import store from "../../index";
import { pdfExport } from "../../../api/pdfExport.api";
import { showErrorNotification } from "../../../utils/error-notifications";

const today = new Date();

const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);

const formattedStartDate = thirtyDaysAgo.toLocaleDateString('en-CA');
const formattedEndDate = today.toLocaleDateString('en-CA');

const defaultPeriod = {
  startDate: formattedStartDate,
  endDate: formattedEndDate
};

export const NewPdfExport = {
  state: {
    selectedPanels: [],
    pdfs: [],
    isRequesting: false,
  },
  mutations: {
    SET_SELECTED_PANELS(state, data) {
      state.selectedPanels = data;
    },
    SET_PDFS(state, pdf) {
      state.pdfs.push(pdf);
    },
    CLEAR_PDFS(state) {
      state.pdfs = [];
    },
    SET_REQUEST_STATUS(state, status) {
      state.isRequesting = status;
    }
  },
  actions: {
    async exportPanelToPdf({ commit }, panelIds) {
      commit("SET_REQUEST_STATUS", true);
      commit("CLEAR_PDFS");

      const period = {
        startDate: store.getters["RangeFilter/filter"].startDate,
        endDate: store.getters["RangeFilter/filter"].endDate,
      }
      try {
        for (const panelId of panelIds) {
          try {
            const { data } = await pdfExport({
              ...period,
              selected_panels: [panelId]
            });

            const selectedPeriod = period.startDate && period.endDate ? period : defaultPeriod;
    
            commit("SET_PDFS", {url: data.body.download_url, panelId, ...selectedPeriod});
          } catch (error) {
            showErrorNotification(`Error exporting panel: ${panelId}`)
            console.error('Error exporting panel:', panelId, error);
          } 
        }
      } catch (error) {
        console.log(error);
      } finally {
        commit("SET_REQUEST_STATUS", false);
      }
    }
  },
  getters: {
    selectedPanels: (state) => state.selectedPanels || [],
  },
  namespaced: true,
}
