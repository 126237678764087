import { httpClient } from "./index";

export const statistics = ({
  panel = null,
  startDate = null,
  endDate = null,
  withWeekend = false
}) =>
  httpClient.get("/dashboard/statistics", {
    params: {
      id_panel: panel,
      filters : {
        start_date: startDate,
        end_date: endDate,
      },
      with_weekends: withWeekend
    },
  });