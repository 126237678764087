<template>
  <div>
    <page-title :title="translate.my_panels" />
    <div class="content">
      <table class="my-panels">
        <thead class="table-head">
          <tr>
            <th
              class="table-title"
              v-for="column in panelsColumnsTranslate"
              :key="column.key"
            >
              <a-skeleton
                :loading="true"
                active
                :paragraph="{ rows: 1 }"
                v-if="loading"
              />
              <span v-if="!loading">
                {{ column.title }}
              </span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody class="table-body" v-if="!loading">
          <tr class="table-data" v-for="row in panelsList" :key="row.key">
            <td>{{ row.identification }}</td>
            <td>{{ row.address }}</td>
            <td>{{ row.timezone }}</td>
            <td>{{ row.cameraStatus === 1 ? "On" : "Off" }}</td>
            <td class="table-btns">
              <button class="table-btn edit-panel" @click="showSettings(row)">
                <a-icon type="edit" />
              </button>
              <UploadImageButton :title="translate.upload_image" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <a-modal
      v-model="visible"
      class="modal"
      :title="translate.panel_config"
      width="800px"
      footer=""
      v-if="!loading"
    >
      <PanelModalForm @handle-save="handleSave" @handle-cancel="handleCancel" />
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageTitle from "../components/elements/PageTitle.vue";
import PanelModalForm from "../components/elements/PanelModalForm.vue";
import UploadImageButton from "../components/elements/UploadImageButton.vue";

let panelsColumns = [];
export default {
  components: {
    PageTitle,
    PanelModalForm,
    UploadImageButton,
  },
  data() {
    return {
      visible: false,
      panelDetails: false,
      panelsColumns,
    };
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
      panelsColumnsTranslate: (state) => {
        const labels = state.labels;
        return (panelsColumns = [
          {
            title: labels.identification,
            dataIndex: "identification",
            key: "identification",
          },
          {
            title: labels.address,
            dataIndex: "address",
            key: "address",
          },
          {
            title: labels.timezone,
            dataIndex: "timezone",
            key: "timezone",
          },
          {
            title: labels.camera_status,
            dataIndex: "cameraStatus",
            key: "cameraStatus",
          },
        ]);
      },
    }),
    ...mapState({
      loading: (state) => state.loading,
    }),
    ...mapState("PanelSelectFilter", {
      panelsList: (state) => {
        let data = [];

        for (let i = 0; i < state.panelsList.length; i++) {
          data.push({
            key: state.panelsList[i].id,
            identification: state.panelsList[i].description,
            address: state.panelsList[i].address,
            timezone: state.panelsList[i].utc,
            cameraStatus: state.panelsList[i].camera_status,
          });
        }

        return data || [];
      },
    }),

    ...mapState("Panel", {
      panel: (state) => state.panel,
    }),
  },
  methods: {
    showPanel(panel) {
      this.panelDetails = true;
      this.getPanel(panel.key);
    },
    showSettings(panel) {
      this.visible = true;
      this.getPanel(panel.key);
    },
    handleSave() {
      this.visible = false;
      this.getPanels();
    },
    handleCancel() {
      this.visible = false;
    },
    ...mapActions("PanelSelectFilter", ["getPanels"]),
    ...mapActions("Panel", ["getPanel"]),
  },
  created() {
    this.getPanels();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px;
  margin-bottom: 20px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  max-height: 590px;
  overflow: auto;
  white-space: nowrap;

  .my-panels {
    border-collapse: collapse;
    width: 100%;

    .table-head {
      border-bottom: 1px solid #e8e8e8;
      background: #fafafa;

      .table-title {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding: 16px;
      }
    }

    .table-body {
      background: #fff;

      .table-data {
        td {
          padding: 16px;
          border-bottom: 1px solid #e8e8e8;
        }

        &:hover {
          background: #e6f7fe;
        }
      }

      .table-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        vertical-align: middle;
        .table-btn {
          border: none;
          background: #fff;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3),
            0px 0px 2px rgba(0, 0, 0, 0.3);
          cursor: pointer;
          border-radius: 5px;
          padding: 0.4rem 0.35rem 0.15rem;

          i {
            font-size: 20px;
            color: #333;
          }

          &:hover {
            transform: scale(1.04);
            background: #fafafa;
          }
        }
      }
    }
  }
}
</style>
