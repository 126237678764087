<template>
  <auth-layout>
    <template v-slot:header>
      <auth-header :title="translate.newpass"></auth-header>
    </template>
    <template v-slot:content>
      <auth-content>
        <a-form @submit.prevent="handleResetPassword">
          <a-input-password
            class="form-item"
            :placeholder="translate.newpass"
            v-model="newPassword"
          >
            <a-icon slot="prefix" type="lock"></a-icon>
          </a-input-password>
          <a-input-password
            class="form-item"
            :placeholder="translate.confirmpass"
            v-model="confirmPassword"
          >
            <a-icon slot="prefix" type="lock"></a-icon>
          </a-input-password>
          <div class="actions">
            <div class="return-page">
              <router-link to="login">
                <a-icon type="arrow-left" class="back-icon" />
                {{ translate.backlogin }}
              </router-link>
            </div>
            <a-button
              type="primary"
              class="dv-primary-btn"
              html-type="submit"
            >
              {{ translate.recover }}
            </a-button>
          </div>
        </a-form>
      </auth-content>
    </template>
    <template v-slot:feedback>
      <div class="feedback-container">
        <a-alert
          v-if="notification.type === 'error'"
          :type="notification.type"
          :message="notification.message"
          show-icon
          closable
        />
      </div>
    </template>
  </auth-layout>
</template>

<script>
import AuthContent from "@/components/elements/AuthContent.vue";
import AuthHeader from "@/components/elements/AuthHeader.vue";
import AuthLayout from "@/components/templates/AuthLayout.vue";
import { getLanguage } from "../../utils/language";
import { mapActions, mapState } from "vuex";

export default {
  components: { AuthHeader, AuthLayout, AuthContent },
  name: "ResetPassword",
  data() {
    let translate = [];
    if ( new URL(location.href).host == 'adtech.datavisiooh.com' ) {
      translate = getLanguage.ES
    } else {
      translate = getLanguage.PT
    }

    return {
      translate,
      newPassword: null,
      confirmPassword: null,
      token: null,
    };
  },
  computed: {
    ...mapState("Common", {
      notification: (state) => state.notification,
    }),
  },
  methods: {
    ...mapActions("Common", ["setNotification"]),
    ...mapActions("Auth", ["resetPassword"]),

    handleResetPassword() {
      if (!this.newPassword || !this.confirmPassword) {
        return this.setNotification({
          type: "error",
          message: "É necessário preencher todos os campos para continuar.",
        });
      }

      const payload = {
        token: this.token,
        password: this.newPassword,
        password_confirmation: this.confirmPassword,
      };

      this.resetPassword(payload);
    },
  },
  created() {
    const { token } = this.$route.query;
    this.token = token;
  },
};
</script>

<style lang="scss" scoped>
.back-icon {
  margin-right: 5px;
}

.form-item {
  margin-bottom: var(--medium-space);
  width: 100%;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--medium-space);
}
</style>
