<template>
  <div>
    <page-title :title="translate.export" />
    <div class="container">
      <RangeFilter />

      <a-button @click="clickPdfButton" :loading="isRequesting">{{ translate.generate }}</a-button>
    </div>

    <div class="content">
      <a-table
        class="table-item"
        :columns="exportColumnsTranslate"
        :dataSource="panels"
        :scroll="{ x: 940, y: 600 }"
        :pagination="false"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: handleToggleCheck,
          hideDefaultSelections: true,
          selections: false,
        }"
      >
        <span
          slot="download"
          slot-scope="record"
        >
          <a v-if="pdfs.find(pdf => pdf?.panelId === record.key)" :href="pdfs.find(pdf => pdf?.panelId === record.key)?.url" target="_blank">
            <a-icon type="download" style="font-size: 24px;" />
            <span style="margin-left: 4px">
              {{ formatDate(pdfs.find(pdf => pdf?.panelId === record.key)?.startDate) }} ~ 
              {{ formatDate(pdfs.find(pdf => pdf?.panelId === record.key)?.endDate) }}
            </span>
          </a>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import PageTitle from "../components/elements/PageTitle.vue";
import RangeFilter from "@/components/elements/RangeFilter";

let exportColumns = [];

export default {
  components: {
    PageTitle,
    RangeFilter,
  },
  data() {
    return {
      selectedRows: [],
      selectedRowKeys: [],
      exportColumns,
      panels: [],
      today: new Date(),
      period: {
        startDate: "",
        endDate: "",
      },
      pdfList: []
    };
  },
  watch: {
    panelsList(data) {
      this.panels = data.map(panel => ({
        key: panel.id,
        identification: panel.description,
        active: panel.active === 2 ? "disabled" : "",
        address: panel.address,
      }));
    },
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
      exportColumnsTranslate: (state) => {
        const labels = state.labels;

        return (exportColumns = [
          {
            title: labels.identification,
            dataIndex: "identification",
            key: "identification",
          },
          {
            title: labels.address,
            dataIndex: "address",
            key: "address",
          },
          {
            title: "Download",
            key: "download",
            scopedSlots: { customRender: "download" }
          }
        ]);
      },
    }),

    ...mapState("PanelSelectFilter", {
      panelsList: (state) => state.panelsList,
    }),

    ...mapState("User", {
      clientId: (state) => state.client.id,
      clientName: (state) => state.client.name_client
    }),

    ...mapState("NewPdfExport", ["pdfs", "selectedPanels", "isRequesting"]),

    ...mapGetters(["RangeFilter/filter"]),
  },
  methods: {
    ...mapMutations("NewPdfExport", {
      setSelectedPanels: "SET_SELECTED_PANELS",
    }),

    ...mapActions("PanelSelectFilter", ["getPanels"]),

    ...mapActions("NewPdfExport", ["exportPanelToPdf"]),

    handleToggleCheck(selectedRowKeys, selectedRows) {
      this.setSelectedPanels(selectedRowKeys);

      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },

    async clickPdfButton() {
      if(this.selectedPanels.length) {
        await this.exportPanelToPdf(this.selectedPanels);
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);

      const formatter = new Intl.DateTimeFormat("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        separator: "/"
      });

      const formattedDate = formatter.format(date);

      return formattedDate;
    }
  },
  created() {
    this.getPanels();
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.content {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  .export-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & .wrapper {
      margin: 0;
    }
  }

  .filters {
    display: flex;
    align-items: center;

    & p {
      margin-right: 1rem;
      margin-bottom: 0;
      color: green;
    }

    & .filters-divider {
      height: 100%;
      margin: 0 1rem 0 0;
    }
  }
}
</style>
