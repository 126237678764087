var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"demographics"},[_c('page-title',{attrs:{"title":"Demográficos"}}),_c('div',{staticClass:"panel-container"},[_c('PanelSelectFilter',{staticClass:"panel-select-filter",attrs:{"title":_vm.translate.panel,"label":_vm.translate.address},on:{"change":_vm.buildCharts}}),(_vm.panel.active === 2)?_c('span',{staticClass:"panel-disabled"},[_vm._v("disabled")]):_vm._e(),_c('RangeFilter',{staticClass:"range-filter",attrs:{"title":_vm.translate.period},on:{"afterSetFilter":_vm.buildCharts}}),_c('div',{staticClass:"btns-wrapper"},[(_vm.loading)?_c('a-skeleton',{attrs:{"loading":true,"active":"","paragraph":{ rows: 1, width: 80 }}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"download-icons"},[_c('PdfIconButton',{attrs:{"title":_vm.translate.pdf_export},on:{"click":_vm.handleDownloadPdf}}),_c('XlsIconButton',{attrs:{"title":_vm.translate.xls_export},on:{"click":_vm.getDemographicsChartXls}})],1):_vm._e()],1)],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"line-bar-container"},[_c('div',{staticClass:"line-graph-wrapper"},[_c('div',{staticClass:"period"},[(_vm.loading)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 1, width: 1 }}}):_vm._e(),(_vm.intervalLabelPeoplePerHourChart && !_vm.loading)?_c('p',{staticClass:"graph-title period-title"},[_vm._v(" "+_vm._s(_vm.intervalLabelPeoplePerHourChart)+" ")]):_vm._e()],1),_c('div',{staticClass:"card-group"},[_c('statistic-card',{attrs:{"title":_vm.translate.total,"value":_vm.cards.pedestres}}),_c('statistic-card',{attrs:{"title":"Total Feminino","type":"feminino","value":_vm.peoplePerHourCards.feminino ? _vm.calcPercent(
                _vm.peoplePerHourCards, 
                _vm.peoplePerHourCards.feminino ?? 0
              )?.toFixed(0) + '%' : 0}}),_c('statistic-card',{attrs:{"title":"Total Masculino","type":"masculino","value":_vm.peoplePerHourCards.masculino ? _vm.calcPercent(
                _vm.peoplePerHourCards, 
                _vm.peoplePerHourCards.masculino ?? 0
              )?.toFixed(0) + '%' : 0}})],1),_c('div',{staticClass:"graph"},[(_vm.loading)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 4 }}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"graph-item"},[_c('p',{staticClass:"graph-title"},[_vm._v(_vm._s(_vm.translate.hourly_flow))]),(
                _vm.peoplePerHourChart.labels &&
                _vm.peoplePerHourChart.datasets &&
                _vm.peoplePerHourChart.labels.length &&
                _vm.peoplePerHourChart.datasets.length
              )?_c('demographic-line-graph',{attrs:{"chartData":_vm.peoplePerHourChart}}):_vm._e(),_c('no-data',{attrs:{"chart":_vm.peoplePerHourChart}})],1):_vm._e()],1)]),_c('div',{staticClass:"bar-graph-wrapper"},[_c('div',{staticClass:"period"},[(_vm.loading)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 1, width: 1 }}}):_vm._e(),(_vm.intervalLabelPeoplePerDayChart && !_vm.loading)?_c('p',{staticClass:"graph-title period-title"},[_vm._v(" "+_vm._s(_vm.intervalLabelPeoplePerDayChart)+" ")]):_vm._e()],1),_c('div',{staticClass:"progress-group"},[_c('progress-circle',{attrs:{"label":_vm.translate.total,"value":_vm.formatNumber(_vm.progress.pedestres),"percent":_vm.calcPercentTotal(_vm.progress, _vm.progress.pedestres),"color":"#4bab68"}}),_c('progress-circle',{attrs:{"label":"Total Feminino","type":"feminino","value":_vm.peoplePerDayProgress.feminino ? _vm.calcPercent(
                _vm.peoplePerDayProgress, 
                _vm.peoplePerDayProgress.feminino ?? 0
              )?.toFixed(0) + '%' : 0,"percent":_vm.peoplePerDayProgress.total ? _vm.calcPercent(
                _vm.peoplePerDayProgress, 
                _vm.peoplePerDayProgress.feminino ?? 0
              ) : 0,"color":"#D33257AA"}}),_c('progress-circle',{attrs:{"label":"Total Masculino","type":"masculino","value":_vm.peoplePerDayProgress.masculino ? _vm.calcPercent(
                _vm.peoplePerDayProgress, 
                _vm.peoplePerDayProgress.masculino ?? 0
              )?.toFixed(0) + '%' : 0,"percent":_vm.peoplePerDayProgress.total ? _vm.calcPercent(
                _vm.peoplePerDayProgress,
                _vm.peoplePerDayProgress.masculino ?? 0
              ) : 0,"color":"#3D8EB9AA"}})],1),_c('div',{staticClass:"graph"},[(_vm.loading)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 4 }}}):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"graph-item"},[_c('p',{staticClass:"graph-title"},[_vm._v(_vm._s(_vm.translate.daily_flow))]),(
                _vm.peoplePerDayChart.labels &&
                _vm.peoplePerDayChart.datasets &&
                _vm.peoplePerDayChart.labels.length &&
                _vm.peoplePerDayChart.datasets.length
              )?_c('stacked-bar-graph',{attrs:{"chartData":_vm.peoplePerDayChart,"id":"demographic-stacked-bar-chart"}}):_vm._e(),_c('no-data',{attrs:{"chart":_vm.peoplePerDayChart}})],1):_vm._e()],1)])]),_c('div',{staticClass:"doughnut-horizontal-container"},[_c('div',{staticClass:"doughnut-graph-wrapper"},[_c('div',{staticClass:"graph"},[_c('div',{staticClass:"period"},[(_vm.loading)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 1, width: 1 }}}):_vm._e(),(_vm.intervalLabelPeoplePerDayChart && !_vm.loading)?_c('p',{staticClass:"graph-title period-title"},[_vm._v(" "+_vm._s(_vm.intervalLabelPeoplePerDayChart)+" ")]):_vm._e()],1),_c('div',{staticClass:"progress-group"},[(typeof _vm.percentePerDay.datasets !== 'undefined')?_c('progress-circle',{attrs:{"label":"% Masculino","value":_vm.formatNumber(_vm.percentePerDay.datasets[0]?.data[1] ?? 0),"percent":_vm.percentePerDay.datasets[0]?.data[1] ?? 0,"color":_vm.percentePerDay.datasets[0]?.backgroundColor[1] ?? ''}}):_vm._e(),(typeof _vm.percentePerDay.datasets !== 'undefined')?_c('progress-circle',{attrs:{"label":"% Feminino","value":_vm.formatNumber(_vm.percentePerDay.datasets[0]?.data[0] ?? 0),"percent":_vm.percentePerDay.datasets[0]?.data[0] ?? 0,"color":_vm.percentePerDay.datasets[0]?.backgroundColor[0] ?? ''}}):_vm._e()],1),(_vm.loading)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 4 }}}):_vm._e(),_c('div',{staticClass:"graph-item"},[_c('p',{staticClass:"graph-title"},[_vm._v(_vm._s(_vm.translate.percentage_gender))]),(
                _vm.percentePerDay.datasets &&
                _vm.percentePerDay.datasets.length
              )?_c('doughnut-graph',{attrs:{"chartData":_vm.percentePerDay}}):_vm._e(),_c('no-data',{attrs:{"chart":_vm.percentePerDay}})],1)],1)]),_c('div',{staticClass:"horizontal-bar-graph-wrapper"},[_c('div',{staticClass:"graph"},[_c('div',{staticClass:"period"},[(_vm.loading)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 1, width: 1 }}}):_vm._e(),(_vm.intervalLabelPeoplePerDayChart && !_vm.loading)?_c('p',{staticClass:"graph-title period-title"},[_vm._v(" "+_vm._s(_vm.intervalLabelPeoplePerDayChart)+" ")]):_vm._e()],1),_c('div',{staticClass:"progress-group"},_vm._l((_vm.peopleByGeneration.generations),function(generation,index){return _c('progress-circle',{key:index,attrs:{"label":generation.label ?? '',"value":_vm.convertToPercentage(generation.total, _vm.peopleByGeneration.totalPeople) + '%',"percent":_vm.peopleByGeneration.totalPeople ? (generation.total * 100) / _vm.peopleByGeneration.totalPeople : 0,"color":generation.color}})}),1),(_vm.loading)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 4 }}}):_vm._e(),_c('div',{staticClass:"graph-item"},[_c('p',{staticClass:"graph-title"},[_vm._v(_vm._s(_vm.translate.age_group))]),(
                _vm.infoPeopleByAge.labels &&
                _vm.infoPeopleByAge.datasets &&
                _vm.infoPeopleByAge.labels.length &&
                _vm.infoPeopleByAge.datasets.length
              )?_c('demographic-bar-graph',{attrs:{"chartData":_vm.infoPeopleByAge,"id":"demographic-bar-chart"}}):_vm._e()],1)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }