<template>
  <div class="impacts">
    <page-title :title="translate.impact"></page-title>

    <div class="panel-container">
      <PanelSelectFilter
        class="panel-select-filter"
        :title="translate.panel"
        :label="translate.address"
        @change="buildCharts"
      />

      <span class="panel-disabled" v-if="panel.active === 2"
        >disabled</span
      >

      <RangeFilter class="range-filter" :title="translate.period" @afterSetFilter="buildCharts" />

      <div class="btns-wrapper">
        <div class="download-icons">
          <PdfIconButton
            :title="translate.pdf_export"
            @click="handleDownloadPdf"
          />
          <XlsIconButton
            :title="translate.xls_export"
            @click="getWeightedChartXls"
          />
          <UploadImageButton
            class="upload-image"
            :title="translate.upload_image"
          />
          <DownloadChartButton 
            @click="downloadCharts" 
            title="Download Charts"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="content">
        <div class="cards">
          <div class="period">
            <a-skeleton active paragraph v-if="loading" />
            <p class="graph-title period-title" v-if="!loading">
              {{ dateInterval }}
            </p>
          </div>
          <div class="card-group">
            <statistic-card
              :title="translate.total_impacts"
              :value="cards.total"
              cardHeight="84px"
              hasTooltipTotalImpacts
            />
            <statistic-card
              :title="translate.person"
              :value="cards.pedestres"
              cardHeight="84px"
              hasTooltipPedestresImpacts
            />
            <statistic-card
              :title="translate.car_weighted"
              :value="cards['veiculos-ponderados']"
              cardHeight="84px"
              hasTooltipPonderadosImpacts
            />
          </div>
        </div>
        <div v-if="isVariableImpact" class="panel-settings">
          <PanelSettingsForm @afterSave="buildCharts" />
        </div>
      </div>
      <div class="graphs">
        <div class="graph">
          <div class="line-graph-wrapper">
            <a-skeleton active :paragraph="{ rows: 4 }" v-if="loading" />
            <div class="graph-item" v-if="!loading">
              <line-graph
                :height="screenHeight <= 880 ? '330' : '400'"
                :chartData="graphPerHour"
                v-if="hasHourlyData"
              ></line-graph>
              <p style="text-align: center" v-else>
                {{ translate.no_data }}
              </p>
            </div>
          </div>
          <div class="bar-graph-wrapper">
            <a-skeleton active :paragraph="{ rows: 4 }" v-if="loading" />
            <div class="graph-item" v-if="!loading">
              <bar-graph
                :height="screenHeight <= 880 ? '330' : '400'"
                :chartData="graphPerDay"
                v-if="hasDailyData"
              ></bar-graph>
              <p style="text-align: center" v-else>
                {{ translate.no_data }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import StatisticCard from "../components/elements/StatisticCard.vue";
import LineGraph from "../components/modules/Graphs/LineGraph.vue";
import BarGraph from "../components/modules/Graphs/BarGraph.vue";
import PageTitle from "@/components/elements/PageTitle.vue";
import RangeFilter from "@/components/elements/RangeFilter";
import PanelSelectFilter from "@/components/elements/PanelSelectFilter";
import PdfIconButton from "@/components/elements/PdfIconButton";
import XlsIconButton from "@/components/elements/XlsIconButton";
import UploadImageButton from "@/components/elements/UploadImageButton";
import PanelSettingsForm from "@/components/elements/PanelSettingsForm";
import DownloadChartButton from "@/components/elements/DownloadChartButton.vue";

export default {
  components: {
    StatisticCard,
    LineGraph,
    BarGraph,
    PageTitle,
    RangeFilter,
    PanelSelectFilter,
    PdfIconButton,
    XlsIconButton,
    UploadImageButton,
    PanelSettingsForm,
    DownloadChartButton,
  },

  data() {
    return {
      hasDailyData: false,
      hasHourlyData: false,
      dateInterval: "",
      screenHeight: null,
    };
  },

  watch: {
    graphPerDay(data) {
      this.hasDailyData = data.labels?.length && data.datasets?.length;
    },

    graphPerHour(data) {
      this.hasHourlyData = data.labels?.length && data.datasets?.length;
    },

    dateIntervalInfo(data) {
      this.dateInterval = data.inicial ? `${data.inicial} ~ ${data.final}` : "";
    },
  },

  computed: {
    ...mapState("Graph", {
      cards: (state) => state.weightedCards,
      graphPerDay: (state) => state.weightedChartPerDay,
      graphPerHour: (state) => state.weightedChartPerHour,
      dateIntervalInfo: (state) => state.periodInfoWeightedChart,
    }),

    ...mapState({
      loading: (state) => state.loading,
    }),

    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),

    ...mapState("PanelSelectFilter", {
      panel: (state) => {
        const value = state.activePanelId;

        const panel =
          value > 0
            ? state.panelsList.filter(({ id }) => id === value)[0]
            : state.panelsList[0];

        return {
          id: panel?.id,
          address: panel?.address,
          active: panel?.active,
        };
      },
    }),

    ...mapState("User", {
      isVariableImpact: (state) => {
        return state.user.variable_impacts || 0
      },
    }),
  },

  methods: {
    ...mapActions("Graph", [
      "getWeightedChartPerDay",
      "getWeightedChartPerHour",
      "getDashboardChartPdf",
      "getWeightedChartXls",
    ]),

    buildCharts() {
      this.getWeightedChartPerDay();
      this.getWeightedChartPerHour();
    },

    formatNumber(num) {
      const n = String(num),
        p = n.indexOf(".");
      return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
        p < 0 || i < p ? `${m}.` : m
      );
    },

    handleDownloadPdf() {
      const lineChart = document.getElementById("line-chart");
      const barChart = document.getElementById("bar-chart");
      const urlLogCustomer = document.querySelector(".customer-logo");

      this.getDashboardChartPdf({
        path: "impactos",
        dataURLLineChart: lineChart.toDataURL(),
        dataURLBarChart: barChart.toDataURL(),
        urlLogCustomer: urlLogCustomer.getAttribute("src"),
        addressPanelCustomer: this.panel.address,
        cardsLineChart: {
          periodo: `${this.dateInterval}`,
          total: this.formatNumber(this.cards.total),
          pedestres: this.formatNumber(this.cards.pedestres),
          veiculosPonderados: this.formatNumber(this.cards["veiculos-ponderados"]),
          veiculos: this.formatNumber(this.cards.veiculos),
        },
      });
    },
    getHeight() {
      this.screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
    },

    downloadCharts() {
      const lineChart = document.getElementById("line-chart");
      const dataURLLineChart = lineChart.toDataURL("image/png");
      const lineChartLink = document.createElement("a");
      lineChartLink.download = "line_chart";
      lineChartLink.href = dataURLLineChart;
      lineChartLink.click();

      const barChart = document.getElementById("bar-chart");
      const dataURLBarChart = barChart.toDataURL("image/png");
      const barChartLink = document.createElement("a");
      barChartLink.download = "bar_chart";
      barChartLink.href = dataURLBarChart;
      barChartLink.click();
    },
  },

  created() {
    this.buildCharts();
  },
};
</script>

<style lang="scss" scoped>
.impacts {
  .panel-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 15px;
    padding: 10px 10px 5px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

    .panel-disabled {
      background: #fff1f0;
      color: #f5222d;
      border: 1px solid #ffa39e;
      padding: 2px 10px;
      border-radius: 4px;
      max-height: 30px;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .btns-wrapper {
      margin-top: 1.5rem;

      .download-icons {
        display: flex;
        gap: 0.5rem;
      }
    }
  }

  .container {
    width: 100%;

    .panel-settings {
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      padding: 5px 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      margin: 15px 0;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;

      .period {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;

        .period-title {
          font-weight: var(--text-bold);
          text-align: center;
          font-size: var(--text-18);
          margin-bottom: 5px;
        }
      }

      .cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
        padding: 5px 30px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin-bottom: 15px;

        .card-group {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          gap: 1.25rem;

          div {
            width: 240px;
            max-width: 100%;
          }
        }
      }
    }
  }

  .graphs {
    margin-bottom: 15px;
    .line-graph-wrapper,
    .bar-graph-wrapper {
      background: #fff;
      padding: 10px 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }

    .line-graph-wrapper {
      margin-bottom: 0;
    }

    .bar-graph-wrapper {
      margin-top: 0;
    }

    .graph {
      max-width: 100%;
      display: grid;
      gap: 15px;

      .graph-item {
        max-width: 100%;
      }

      & .graph-title {
        font-weight: var(--text-bold);
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 865px) {
  .impacts {
    .panel-container {
      .panel-disabled {
        margin-top: 13px;
      }
    }
  }
}

@media screen and (min-width: 1340px) {
  .impacts {
    .panel-container {
      justify-content: space-between;
      padding-bottom: 0;
    }

    .cards {
      align-items: flex-start;
    }

    .graphs {
      .line-graph-wrapper {
        margin-right: 7.5px;
      }

      .bar-graph-wrapper {
        margin-left: 7.5px;
      }

      .graph {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 0;
        padding: 0;
      }
    }
  }
}
</style>
