<template>
  <div class="input-container">
    <label for="spot">
      {{ spotLabel }}
      <a-tooltip class="info-tooltip">
        <template slot="title">
          {{ spotTooltip }}
        </template>
        <a-icon
          style="color: var(--blue-50)"
          type="info-circle"
          theme="filled"
        />
      </a-tooltip>
    </label>
    <input
      class="input-value"
      type="number"
      step="1"
      :value="spotValue"
      @input="updateValue($event.target.value)"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
  </div>
</template>

<script>
export default {
  name: "SpotInput",

  props: {
    spotLabel: {
      type: String,
      default: "",
    },
    spotTooltip: {
      type: String,
      default: "",
    },
    spotValue: {
      type: Number,
      default: null,
    },
  },

  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  .input-value {
    margin-top: 4px;
    width: 100%;
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;

    &:focus,
    &:active {
      border-color: #40a9ff;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
}
</style>
