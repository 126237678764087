<template>
  <div class="wrapper" @click="filterByClass">
    <a-skeleton :loading="true" active title v-if="loading" />
    <div
      class="container"
      :class="{ hasBorder: type === activeClassFilter }"
      v-if="!loading"
    >
      <a-progress
        class="progress-circle"
        type="circle"
        :percent="percent"
        :width="75"
        :strokeColor="color"
        :format="(percent) => value"
        status="normal"
      />
      <p>{{ label }}</p>
    </div>
    <div
      :class="{ active: type === activeClassFilter }"
      :style="{ background: bgColor }"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    bgColor: {
      type: String,
      default: "var(--blue-50)",
    },
    percent: {
      type: [Number, String],
      default: 0,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapState("User", ["activeClassFilter"]),
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapMutations("User", {
      setActiveClass: "SET_ACTIVE_CLASS_FILTER",
    }),
    ...mapActions("Graph", ["getBarChart"]),

    filterByClass() {
      this.setActiveClass(this.type);
      this.getBarChart(this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  .active {
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 3px 3px;
    margin-top: -5px;
  }

  &:hover .active {
    opacity: 1;
    animation: showActive 0.3s;
  }
}

.container {
  padding: 9px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #fff;
  cursor: pointer;

  transition: all 0.3s;

  .progress-circle {
    text-align: center;
    font-size: var(--text-14);
    font-weight: var(--text-semibold);
  }

  & p {
    margin: 0;
    color: #00000073;
  }

  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
  }
}

.hasBorder {
  width: 100%;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);

  border-radius: 5px;
}
</style>
