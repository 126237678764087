<template>
  <div class="container">
    <a-result
      status="500"
      title="404"
      sub-title="A página que você está tentando acessar não foi encontrada."
    >
      <template #extra>
        <a-button type="primary" @click="$router.push('/')">
          Voltar ao início
        </a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
