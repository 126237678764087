<template>
  <p
    v-if="
      chart.labels &&
      chart.datasets &&
      !chart.labels.length &&
      !chart.datasets.length
    "
  >
    {{ translate.no_data }}
  </p>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "NoData",
  props: {
    chart: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
  },
};
</script>

<style lang="scss" scoped>
p {
  text-align: center;
}
</style>
