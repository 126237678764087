<bar-chart
  :chartdata="chartData"
  :height="height"
  :options="chartOptions"
  :style="style"
></bar-chart>

<script>
import { Bar, mixins } from "vue-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            min: 0,
            ticks: {
              display: false,
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]?.toFixed(0);
              return data.datasets[tooltipItem.datasetIndex].label + ': ' + value + '%';
            }
          }
        },
        plugins: {
          datalabels: {
            color: function(context) {
              return context.dataset.data[context.dataIndex] > 4 ? "white" : "#222";
            },
            font: {
              weight: "bold",
              size: "14"
            },
            align: "end",
            offset: function(context) {
              return context.dataset.data[context.dataIndex] > 20 ? 0 : context.dataset.data[context.dataIndex] > 4 ? -15 : 0;
            },
            display: function(context) {
              return context.dataset.data[context.dataIndex] > 0;
            },
            formatter: function(value) {
              return value.toFixed(0) + "%";
            }
          }
        }
      },
    }
  },
  mounted() {
    this.addPlugin(ChartDataLabels)
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style></style>
