<template>
  <div class="wrapper" @click="setLineFilter">
    <div class="container" :class="{ hasBorder: type === activeLineFilter }">
      <a-skeleton :loading="true" active title v-if="loading" />
      <div class="card" v-if="!loading" :style="`height: ${cardHeight}`">
        <a-statistic
          class="card-title"
          :title="title"
          :value="value"
          groupSeparator="."
        />
        <a-tooltip class="info-tooltip" v-if="hasTooltipTotal">
          <template slot="title">
            {{ tipMessageTotal }}
          </template>
          <a-icon
            style="color: var(--blue-50)"
            type="info-circle"
            theme="filled"
          />
        </a-tooltip>
        <a-tooltip class="info-tooltip" v-if="hasTooltipPedestres">
          <template slot="title">
            {{ tipMessagePedestres }}
          </template>
          <a-icon
            style="color: var(--blue-50)"
            type="info-circle"
            theme="filled"
          />
        </a-tooltip>
        <a-tooltip class="info-tooltip" v-if="hasTooltipVeiculos">
          <template slot="title">
            {{ tipMessageVeiculos }}
          </template>
          <a-icon
            style="color: var(--blue-50)"
            type="info-circle"
            theme="filled"
          />
        </a-tooltip>
        <a-tooltip class="info-tooltip" v-if="hasTooltipTotalImpacts">
          <template slot="title">
            {{ tipMessageTotalImpacts }}
          </template>
          <a-icon
            style="color: var(--blue-50)"
            type="info-circle"
            theme="filled"
          />
        </a-tooltip>
        <a-tooltip class="info-tooltip" v-if="hasTooltipPedestresImpacts">
          <template slot="title">
            {{ tipMessagePedestresImpacts }}
          </template>
          <a-icon
            style="color: var(--blue-50)"
            type="info-circle"
            theme="filled"
          />
        </a-tooltip>
        <a-tooltip class="info-tooltip" v-if="hasTooltipPonderadosImpacts">
          <template slot="title">
            {{ tipMessagePonderadosImpacts }}
          </template>
          <a-icon
            style="color: var(--blue-50)"
            type="info-circle"
            theme="filled"
          />
        </a-tooltip>
        <a-tooltip class="info-tooltip" v-if="hasTooltipVeiculosImpacts">
          <template slot="title">
            {{ tipMessageVeiculosImpacts }}
          </template>
          <a-icon
            style="color: var(--blue-50)"
            type="info-circle"
            theme="filled"
          />
        </a-tooltip>
      </div>
    </div>
    <div
      :class="{ active: type === activeLineFilter }"
      :style="{ background: bgColor }"
    ></div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  props: {
    hasTooltipTotal: {
      type: Boolean,
      default: false,
    },
    hasTooltipPedestres: {
      type: Boolean,
      default: false,
    },
    hasTooltipVeiculos: {
      type: Boolean,
      default: false,
    },
    hasTooltipTotalImpacts: {
      type: Boolean,
      default: false,
    },
    hasTooltipPedestresImpacts: {
      type: Boolean,
      default: false,
    },
    hasTooltipPonderadosImpacts: {
      type: Boolean,
      default: false,
    },
    hasTooltipVeiculosImpacts: {
      type: Boolean,
      default: false,
    },
    tipMessageTotal: {
      type: String,
      default: "Total: Pedestres + Veículos",
    },
    tipMessagePedestres: {
      type: String,
      default: "Total de pedestres",
    },
    tipMessageVeiculos: {
      type: String,
      default: "Total de veículos",
    },
    tipMessageTotalImpacts: {
      type: String,
      default: "Total: Pedestes + Veículos ponderados",
    },
    tipMessagePedestresImpacts: {
      type: String,
      default: "Total de pedestres",
    },
    tipMessagePonderadosImpacts: {
      type: String,
      default: "Total de veículos ponderados",
    },
    tipMessageVeiculosImpacts: {
      type: String,
      default: "Total de veículos",
    },
    title: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
      default: "",
    },
    bgColor: {
      type: String,
      default: "var(--blue-50)",
    },
    cardHeight: {
      type: String,
      default: "116px",
    },
    type: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState("User", ["activeLineFilter"]),
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapMutations("User", {
      setActiveLine: "SET_ACTIVE_LINE_FILTER",
    }),

    setLineFilter() {
      this.setActiveLine(this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  .active {
    min-width: 100%;
    height: 5px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 0 0 3px 3px;
    margin-top: -5px;
  }

  &:hover .active {
    opacity: 1;
    animation: showActive 0.3s;
  }
}

.container {
  margin-right: 20px;
  width: 100%;
  cursor: pointer;

  .card {
    height: 116px;
    padding: 10px 20px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.08),
      -1px -1px 5px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    text-align: left;

    .info-tooltip {
      align-self: flex-end;
    }
  }

  &:hover {
    opacity: 1;
    animation: showActive 0.3s;
  }
}

@media screen and (max-width: 1000px) {
  .active {
    right: 0;
  }
}
</style>
