export function convertDataToPercentage(data) {
  const total = new Array(data.labels.length).fill(0);

  data.datasets.forEach(dataset => {
      dataset.data = dataset.data.map(value => parseFloat(value));
  });

  data.datasets.forEach(dataset => {
      dataset.data.forEach((value, index) => {
          total[index] += value;
      });
  });

  data.datasets.forEach(dataset => {
      dataset.data = dataset.data.map((value, index) => {
          if (total[index] !== 0) {
              return (value / total[index] * 100);
          } else {
              return value === 0 ? 0 : 100;
          }
      });
  });

  return data;
}
