<template>
  <auth-layout>
    <template v-slot:header>
      <auth-header :title="translate.login"></auth-header>
    </template>
    <template v-slot:content>
      <auth-content>
        <a-form @submit.prevent="handleLogin">
          <a-input class="form-item" placeholder="Email" v-model="user.email">
            <a-icon slot="prefix" type="user" />
          </a-input>
          <a-input-password
            class="form-item"
            :placeholder="translate.pass "
            v-model="user.password"
          >
            <a-icon slot="prefix" type="lock"></a-icon>
          </a-input-password>
          <div class="actions">
            <a-checkbox>
              {{ translate.remember }}
            </a-checkbox>
            <a-button
              type="primary"
              class="dv-primary-btn"
              html-type="submit"
            >
              Entrar
            </a-button>
          </div>
        </a-form>
      </auth-content>
    </template>
    <template v-slot:extra>
      <div class="forgot-cta">
        <router-link to="recuperar-senha">
          {{ translate.reset }}
        </router-link>
      </div>
    </template>
    <template v-slot:feedback>
      <div class="feedback-container">
        <a-alert
          v-if="notification.type === 'error'"
          :type="notification.type"
          :message="notification.message"
          show-icon
          closable
        />
      </div>
    </template>
  </auth-layout>
</template>

<script>
import AuthContent from "@/components/elements/AuthContent.vue";
import AuthHeader from "@/components/elements/AuthHeader.vue";
import AuthLayout from "@/components/templates/AuthLayout";
import { getLanguage } from "../../utils/language";
import { mapActions, mapState } from "vuex";

export default {
  components: { AuthHeader, AuthLayout, AuthContent },
  name: "Login",
  data() {
    let translate = [];
    if ( new URL(location.href).host == 'adtech.datavisiooh.com' || 
      new URL(location.href).host == 'adtechlatam.datavisiooh.com' ) {
      translate = getLanguage.ES
    } else {
      translate = getLanguage.PT
    }

    return {
      translate,
      user: {
        email: "",
        password: "",
      }
    };
  },
  computed: {
    ...mapState("Common", {
      notification: (state) => state.notification,
    }),

  },
  methods: {
    ...mapActions("Auth", ["login"]),
    ...mapActions("Common", ["setNotification"]),
    handleLogin() {
      const { user } = this;

      if (!user.email || !user.password)
        return this.setNotification({
          type: "error",
          message: "É necessário preencher o email e a senha.",
        });

      const payload = {
        email: user.email,
        password: user.password,
      };

      this.login(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item {
  margin-bottom: var(--medium-space);
  width: 100%;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--medium-space);
}

.forgot-cta {
  text-align: center;
}

.feedback-container {
  margin: 2rem 0;
}

@media screen and (max-height: 600px) and (orientation: landscape) {
  .form-item {
    margin-bottom: 10px;
  }

  .actions {
    margin-bottom: 0;
  }
}
</style>
