<template>
  <div>
    <page-title :title="translate.comparative"></page-title>
    <div>
      <div class="comparative">
        <div class="container">
          <RangeFilter @afterSetFilter="getComparatives" />
          <XlsIconButton
            :title="translate.xls_export"
            @click="getComparativesXls"
          />
        </div>
        <div class="content">
          <div class="period-data">
            <p class="graph-title period-title" v-if="intervalLabel">
              {{ translate.period }}: {{ intervalLabel }}
            </p>
          </div>
          <div class="comparative-data">
            <subtitle :hasTooltip="false">
              {{ translate.comparative_points }}
            </subtitle>
            <a-table
              class="comparative-table"
              :columns="comparativeColumnsTranslate"
              :data-source="panelsList"
              :pagination="false"
              :scroll="{ x: 1500 }"
              size="small"
            >
              <span
                slot="active"
                slot-scope="active"
                v-if="active === 'disabled'"
              >
                <a-tag color="red">
                  {{ active }}
                </a-tag>
              </span>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Subtitle from "../components/elements/Subtitle.vue";
import PageTitle from "../components/elements/PageTitle.vue";
import RangeFilter from "@/components/elements/RangeFilter";
import XlsIconButton from "@/components/elements/XlsIconButton";

let comparativeColumns = [];

export default {
  components: { PageTitle, Subtitle, RangeFilter, XlsIconButton },
  data() {
    return {
      comparativeColumns,
    };
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
      comparativeColumnsTranslate: (state) => {
        const labels = state.labels;

        return (comparativeColumns = [
          {
            title: labels.identification,
            dataIndex: "identification",
            key: "identification",
            fixed: "left",
            width: 300,
          },
          {
            title: "",
            dataIndex: "active",
            key: "active",
            scopedSlots: { customRender: "active" },
          },
          {
            title: labels.total,
            dataIndex: "total",
            key: "total",
            sorter: (a, b) => parseFloat(a.total) - parseFloat(b.total),
          },
          {
            title: labels.pedestrians,
            dataIndex: "pedestrians",
            key: "pedestrians",
            sorter: (a, b) =>
              parseFloat(a.pedestrians) - parseFloat(b.pedestrians),
          },
          {
            title: labels.car,
            dataIndex: "car",
            key: "car",
            sorter: (a, b) => parseFloat(a.car) - parseFloat(b.car),
          },
          {
            title: labels.bus,
            dataIndex: "bus",
            key: "bus",
            sorter: (a, b) => parseFloat(a.bus) - parseFloat(b.bus),
          },
          {
            title: labels.motorcycle,
            dataIndex: "motorcycle",
            key: "motorcycle",
            sorter: (a, b) =>
              parseFloat(a.motorcycle) - parseFloat(b.motorcycle),
          },
          {
            title: labels.truck,
            dataIndex: "truck",
            key: "truck",
            sorter: (a, b) => parseFloat(a.truck) - parseFloat(b.truck),
          },
          {
            title: labels.bicycle,
            dataIndex: "bicycle",
            key: "bicycle",
            sorter: (a, b) => parseFloat(a.bicycle) - parseFloat(b.bicycle),
          },
          {
            title: labels.impact,
            dataIndex: "impacts",
            key: "impacts",
            sorter: (a, b) => parseFloat(a.impacts) - parseFloat(b.impacts),
          },
          {
            title: labels.predominance,
            dataIndex: "predominance",
            key: "predominance",
          },
        ]);
      },
    }),
    ...mapState("Comparative", {
      listComparatives: (state) => state.list,
      panelsList: (state) => {
        let row = null;
        let data = [];

        const format = (num) => {
          const n = String(num),
            p = n.indexOf(".");

          return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
            p < 0 || i < p ? `${m}.` : m
          );
        };

        for (let i = 0; i < state.list.length; i++) {
          row = state.list[i];

          data.push({
            key: i,
            identification: `${row.name} - ${row.address} `,
            active: `${row.active === 2 ? "disabled" : ""}`,
            total: format(row.total_all_classes),
            pedestrians: format(row.total_pedestrians),
            car: format(row.total_cars),
            bus: format(row.total_buses),
            motorcycle: format(row.total_motorcycles),
            truck: format(row.total_trucks),
            bicycle: format(row.total_bicycles),
            impacts: format(row.total_weighted.toFixed(1)),
            predominance: row.predominance.all,
          });
        }

        return data || [];
      },
      intervalLabel: (state) => {
        const label = state.periodInfo;

        return label.start_date
          ? `${label.start_date} ~ ${label.end_date}`
          : "";
      },
    }),
  },
  methods: {
    ...mapActions("Comparative", ["getComparatives", "getComparativesXls"]),
  },
  created() {
    this.getComparatives();
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
  margin-top: 15px;
  padding: 10px;

  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.content {
  padding: 20px 20px 20px;
  margin-bottom: 20px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  .comparative-header {
    & .wrapper {
      margin: 0;
    }
  }
}

.period-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.period-title {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}

.period-title {
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}
</style>
