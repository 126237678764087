<template>
  <div class="panel-settings-form">
    <subtitle class="subtitle">
      {{ translate.variables }}
    </subtitle>
    <div class="input-variables">
      <div class="input-group">
        <div class="input-container">
          <label>{{ translate.car }}</label>
          <a-input-number
            class="input-number"
            decimalSeparator=","
            :step="0.1"
            :min="0"
            :max="100"
            placeholder="0"
            v-model="setting.car"
          />
        </div>
        <div class="input-container">
          <label>{{ translate.bus }}</label>
          <a-input-number
            class="input-number"
            decimalSeparator=","
            :step="0.1"
            :min="0"
            :max="100"
            placeholder="0"
            v-model="setting.bus"
          />
        </div>
        <div class="input-container">
          <label>{{ translate.motorcycle }}</label>
          <a-input-number
            class="input-number"
            decimalSeparator=","
            :step="0.1"
            :min="0"
            :max="100"
            placeholder="0"
            v-model="setting.motorcycle"
          />
        </div>
        <div class="input-container">
          <label>{{ translate.truck }}</label>
          <a-input-number
            class="input-number"
            decimalSeparator=","
            :step="0.1"
            :min="0"
            :max="100"
            placeholder="0"
            v-model="setting.truck"
          />
        </div>
      </div>
      <a-button type="primary" class="dv-primary-btn calc-btn" @click="handleSaveSetting">
        {{ translate.apply }}
      </a-button>
    </div>
  </div>
</template>

<script>
import Subtitle from "@/components/elements/Subtitle";

import { mapState, mapActions } from "vuex";

export default {
  name: "CampaignSettingsForm",

  components: { Subtitle },

  data() {
    return {
      setting: {
        car: null,
        bus: null,
        motorcycle: null,
        truck: null,
      },
    };
  },

  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
  },

  methods: {
    handleSaveSetting() {
      if (
        this.setting.car !== null ||
        this.setting.bus !== null ||
        this.setting.motorcycle !== null ||
        this.setting.truck !== null
      ) {
        this.saveCampaignSetting(this.setting);
      }
    },
    ...mapActions("Cpm", ["saveCampaignSetting"]),
  },
};
</script>
<style lang="scss" scoped>
.panel-settings-form {
  text-align: left;

  .subtitle {
    width: 100%;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 100%;

    .input-container {
      display: flex;
      flex-direction: column;

      .input-number {
        max-width: 100%;
      }
    }
  }

  .calc-btn {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 425px) {
  .panel-settings-form {
    .input-group {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@media screen and (min-width: 510px) {
  .panel-settings-form {
    .calc-btn {
      margin-left: 2rem;
      margin-top: 1.3rem;
    }

    .input-variables {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .input-group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 830px) {
  .panel-settings-form {
    .input-group {
      gap: 2rem;
    }
  }
}
</style>
