<template>
  <div class="panel-settings-form">
    <subtitle class="subtitle">
      {{ translate.variables }}
    </subtitle>
    <div class="input-variables">
      <div class="input-group">
        <div class="input-container">
          <label>{{ translate.car }}</label>
          <a-input-number
            class="input-number"
            decimalSeparator=","
            :step="0.1"
            :min="0"
            :max="100"
            placeholder="0"
            v-model="setting.car"
          />
        </div>
        <div class="input-container">
          <label>{{ translate.bus }}</label>
          <a-input-number
            class="input-number"
            decimalSeparator=","
            :step="0.1"
            :min="0"
            :max="100"
            placeholder="0"
            v-model="setting.bus"
          />
        </div>
        <div class="input-container">
          <label>{{ translate.motorcycle }}</label>
          <a-input-number
            class="input-number"
            decimalSeparator=","
            :step="0.1"
            :min="0"
            :max="100"
            placeholder="0"
            v-model="setting.motorcycle"
          />
        </div>
        <div class="input-container">
          <label>{{ translate.truck }}</label>
          <a-input-number
            class="input-number"
            decimalSeparator=","
            :step="0.1"
            :min="0"
            :max="100"
            placeholder="0"
            v-model="setting.truck"
          />
        </div>
      </div>
      <a-button
        type="primary"
        class="dv-primary-btn calc-btn"
        @click="handleSaveSetting"
      >
        {{ translate.calculate }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Subtitle from "../elements/Subtitle.vue";

export default {
  name: "PanelSettingsForm",

  components: { Subtitle },

  data() {
    return {
      setting: {
        car: null,
        bus: null,
        moto: null,
        truck: null,
      },
    };
  },

  watch: {
    panelSetting(value) {
      if (!value) return;

      this.setting = { ...value };
    },

    activePanelId(value) {
      if (null !== value) this.getPanelSetting();
    },
  },

  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),

    ...mapState("PanelSetting", {
      panelSetting: (state) => state.config,
    }),

    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),

    ...mapState("PanelSelectFilter", {
      activePanelId: (state) => state.activePanelId,
    }),
  },

  methods: {
    ...mapActions("PanelSetting", ["getPanelSetting", "savePanelSetting"]),

    handleSaveSetting() {
      this.savePanelSetting(this.setting);
      this.$emit("afterSave");
    },
  },

  created() {
    this.getPanelSetting();
  }
};
</script>
<style lang="scss" scoped>
.panel-settings-form {
  text-align: left;

  .subtitle {
    width: 100%;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 100%;

    .input-container {
      display: flex;
      flex-direction: column;

      .input-number {
        max-width: 100%;
      }
    }
  }

  .calc-btn {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 425px) {
  .panel-settings-form {
    .input-group {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@media screen and (min-width: 510px) {
  .panel-settings-form {
    .calc-btn {
      margin-left: 2rem;
      margin-top: 1.5rem;
    }

    .input-variables {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .input-group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 830px) {
  .panel-settings-form {
    .input-group {
      gap: 2rem;
    }
  }
}
</style>
