import store from "../../index";
import { getPanelSetting, setPanelSetting } from "../../../api/panel.api";
import { showErrorNotification } from "../../../utils/error-notifications";

export const PanelSetting = {
  state: {
    config : {}
  },

  mutations: {
    SET_CONFIG(state, config) {
      state.config = config;
    },
  },

  actions: {
    async getPanelSetting({ commit }) {
      const panelId = store.getters["PanelSelectFilter/activePanelId"];

      commit("SET_LOADING", true, { root: true });
      try {
        const {
          data: { body },
        } = await getPanelSetting({ panelId });

        commit("SET_CONFIG", body.panel_settings);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async savePanelSetting({ commit }, setting) {
      const panelId = store.getters["PanelSelectFilter/activePanelId"];

      commit("SET_LOADING", true, { root: true });

      try {
        await setPanelSetting({ panelId, ...setting });
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {},
  namespaced: true,
};
