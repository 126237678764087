import store from "../../index";
import { pdfExport } from "../../../api/pdfExport.api";
import { showErrorNotification } from "../../../utils/error-notifications";

export const PdfExport = {
  state: {
    selectedPanels: [],
  },
  mutations: {
    SET_SELECTED_PANELS(state, data) {
      state.selectedPanels = data;
    }
  },
  actions: {
    async getPdfExportUrl({ commit }) {
      const filters = {
        selected_panels: store.getters["PdfExport/selectedPanels"],
        ...store.getters["RangeFilter/filter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await pdfExport(filters);

        if (data.success) window.location.assign(data.body.download_url);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {
    selectedPanels: (state) => state.selectedPanels || [],
  },
  namespaced: true,
};
