<template>
  <h1 class="page-title">{{ title }}</h1>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  padding: 10px 20px;
  border-radius: 0 0 5px 5px;
  background-color: var(--blue-50);
  color: #fff;
  font-size: 20px;
  font-weight: var(--text-semibold);
}

@media screen and (min-width: 500px) {
  .page-title {
    text-align: center;
  }
}
</style>
