import axios from "axios";
import router from "@/router";
import { getToken } from "../utils/auth";

export const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 100000,
});

const publicRoutes = [
  "/auth/login",
  "/auth/recover-password",
  "/auth/new-password",
];

const authInterceptor = (config) => {
  if(publicRoutes.includes(config.url)) return config;

  config.headers["Authorization"] = `Bearer ${getToken()}`;
  return config;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response?.status === 401) {
    localStorage.removeItem("user");
    router.push("/login")
  }
  return Promise.reject(error);
});

export default httpClient;