const notificationInitialState = {
  type: null,
  message: null,
};

export const Common = {
  state: {
    myProfileModalState: false,
    mySettingsModalState: false,
    notification: notificationInitialState,
  },
  mutations: {
    SET_PROFILE_MODAL_STATE(state, data) {
      state.myProfileModalState = data;
    },

    SET_SETTINGS_MODAL_STATE(state, data) {
      state.mySettingsModalState = data;
    },

    SET_NOTIFICATION(state, { type, message }) {
      state.notification.type = type;
      state.notification.message = message;
    },
  },
  actions: {
    setNotification({ commit }, payload) {
      commit("SET_NOTIFICATION", payload);

      setTimeout(() => {
        commit("SET_NOTIFICATION", { type: null, message: null });
      }, 5000);
    },
  },
  getters: {},
  namespaced: true,
};
