import { render, staticRenderFns } from "./NoData.vue?vue&type=template&id=0f8f5e31&scoped=true"
import script from "./NoData.vue?vue&type=script&lang=js"
export * from "./NoData.vue?vue&type=script&lang=js"
import style0 from "./NoData.vue?vue&type=style&index=0&id=0f8f5e31&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f8f5e31",
  null
  
)

export default component.exports