import Vue from "vue";
import Vuex from "vuex";

import {
  Common,
  User,
  Auth,
  Graph,
  Statistic,
  Comparative,
  PdfExport,
  RangeFilter,
  PanelSelectFilter,
  Translate,
  Panel,
  PanelSetting,
  Paypal,
  Cpm,
  Demographics,
  Lpr,
  NewPdfExport,
} from "./Modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    loadingCount: 0,
  },
  mutations: {
    SET_LOADING(state, data) {
      if (!data) state.loadingCount = state.loadingCount - 1;
      if (data) state.loadingCount = state.loadingCount + 1;
      if (state.loadingCount <= 0) state.loading = false;
      if (state.loadingCount > 0) state.loading = true;
    },
  },
  actions: {},
  modules: {
    Common,
    User,
    Auth,
    Graph,
    Statistic,
    Comparative,
    PdfExport,
    RangeFilter,
    PanelSelectFilter,
    Translate,
    Panel,
    PanelSetting,
    Paypal,
    Cpm,
    Demographics,
    Lpr,
    NewPdfExport,
  },
});
