<template>
  <label for="file" class="upload-image">
    <a-tooltip class="upload-btn" placement="topLeft">
      <template slot="title">
        <span>{{ title }}</span>
      </template>
      <a-icon type="file-image" theme="twoTone" two-tone-color="#2C82C9" />
      <input
        class="upload-file"
        id="file"
        type="file"
        name="file"
        ref="file"
        @change="handleFileUpload"
      />
    </a-tooltip>
  </label>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions("Graph", ["uploadPdfReportImage"]),

    handleFileUpload() {
      const file = this.$refs.file.files[0];

      this.uploadPdfReportImage(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-image {
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3), 0px 0px 2px rgba(0, 0, 0, 0.3);
  background: #fff;

  .upload-btn {
    font-size: 24px;
  }

  &:hover {
    transform: scale(1.04);
    background: #fafafa;
  }
}
.upload-file {
  background: #fff;
  outline: none;
  max-width: 190px;
  display: none;
}
</style>
