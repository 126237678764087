<template>
  <div class="demographics">
    <page-title title="Demográficos"></page-title>

    <div class="panel-container">
      <PanelSelectFilter
        class="panel-select-filter"
        :title="translate.panel"
        :label="translate.address"
        @change="buildCharts"
      />

      <span class="panel-disabled" v-if="panel.active === 2">disabled</span>

      <RangeFilter 
        class="range-filter"
        :title="translate.period"
        @afterSetFilter="buildCharts" 
      />
      <div class="btns-wrapper">
        <a-skeleton
          :loading="true"
          active
          :paragraph="{ rows: 1, width: 80 }"
          v-if="loading"
        />
        <div class="download-icons" v-if="!loading">
          <PdfIconButton
            :title="translate.pdf_export"
            @click="handleDownloadPdf"
          />
          <XlsIconButton
            :title="translate.xls_export"
            @click="getDemographicsChartXls"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="line-bar-container">
        <div class="line-graph-wrapper">
          <div class="period">
            <a-skeleton
              active
              :paragraph="{ rows: 1, width: 1 }"
              v-if="loading"
            />
            <p
              class="graph-title period-title"
              v-if="intervalLabelPeoplePerHourChart && !loading"
            >
              {{ intervalLabelPeoplePerHourChart }}
            </p>
          </div>
          <div class="card-group">
            <statistic-card
              :title="translate.total"
              :value="cards.pedestres"
            />
            <statistic-card
              title="Total Feminino"
              type="feminino"
              :value="peoplePerHourCards.feminino ? calcPercent(
                  peoplePerHourCards, 
                  peoplePerHourCards.feminino ?? 0
                )?.toFixed(0) + '%' : 0"
            />
            <statistic-card
              title="Total Masculino"
              type="masculino"
              :value="peoplePerHourCards.masculino ? calcPercent(
                  peoplePerHourCards, 
                  peoplePerHourCards.masculino ?? 0
                )?.toFixed(0) + '%' : 0"
            />
          </div>
          <div class="graph">
            <a-skeleton active :paragraph="{ rows: 4 }" v-if="loading" />
            <div class="graph-item" v-if="!loading">
              <p class="graph-title">{{ translate.hourly_flow }}</p>
              <demographic-line-graph
                :chartData="peoplePerHourChart"
                v-if="
                  peoplePerHourChart.labels &&
                  peoplePerHourChart.datasets &&
                  peoplePerHourChart.labels.length &&
                  peoplePerHourChart.datasets.length
                "
              ></demographic-line-graph>
              <no-data :chart="peoplePerHourChart" />
            </div>
          </div>
        </div>
        <div class="bar-graph-wrapper">
          <div class="period">
            <a-skeleton
              active
              :paragraph="{ rows: 1, width: 1 }"
              v-if="loading"
            />
            <p
              class="graph-title period-title"
              v-if="intervalLabelPeoplePerDayChart && !loading"
            >
              {{ intervalLabelPeoplePerDayChart }}
            </p>
          </div>
          <div class="progress-group">
            <progress-circle
              :label="translate.total"
              :value="formatNumber(progress.pedestres)"
              :percent="calcPercentTotal(progress, progress.pedestres)"
              color="#4bab68"
            />
            <progress-circle
              label="Total Feminino"
              type="feminino"
              :value="peoplePerDayProgress.feminino ? calcPercent(
                  peoplePerDayProgress, 
                  peoplePerDayProgress.feminino ?? 0
                )?.toFixed(0) + '%' : 0"
              :percent="
                peoplePerDayProgress.total ? calcPercent(
                  peoplePerDayProgress, 
                  peoplePerDayProgress.feminino ?? 0
                ) : 0
              "
              color="#D33257AA"
            />
            <progress-circle
              label="Total Masculino"
              type="masculino"
              :value="peoplePerDayProgress.masculino ? calcPercent(
                  peoplePerDayProgress, 
                  peoplePerDayProgress.masculino ?? 0
                )?.toFixed(0) + '%' : 0"
              :percent="
                peoplePerDayProgress.total ? calcPercent(
                  peoplePerDayProgress,
                  peoplePerDayProgress.masculino ?? 0
                ) : 0
              "
              color="#3D8EB9AA"
            />
          </div>
          <div class="graph">
            <a-skeleton active :paragraph="{ rows: 4 }" v-if="loading" />
            <div class="graph-item" v-if="!loading">
              <p class="graph-title">{{ translate.daily_flow }}</p>
              <stacked-bar-graph
                :chartData="peoplePerDayChart"
                v-if="
                  peoplePerDayChart.labels &&
                  peoplePerDayChart.datasets &&
                  peoplePerDayChart.labels.length &&
                  peoplePerDayChart.datasets.length
                "
                id="demographic-stacked-bar-chart"
              ></stacked-bar-graph>
              <no-data :chart="peoplePerDayChart" />
            </div>
          </div>
        </div>
      </div>
      <div class="doughnut-horizontal-container">
        <div class="doughnut-graph-wrapper">
          <div class="graph">
            <div class="period">
              <a-skeleton
                active
                :paragraph="{ rows: 1, width: 1 }"
                v-if="loading"
              />
              <p
                class="graph-title period-title"
                v-if="intervalLabelPeoplePerDayChart && !loading"
              >
                {{ intervalLabelPeoplePerDayChart }}
              </p>
            </div>
            
            <div class="progress-group">
              <progress-circle
                v-if="typeof percentePerDay.datasets !== 'undefined'"
                label="% Masculino"
                :value="formatNumber(percentePerDay.datasets[0]?.data[1] ?? 0)"
                :percent="percentePerDay.datasets[0]?.data[1] ?? 0"
                :color="percentePerDay.datasets[0]?.backgroundColor[1] ?? ''" 
              />
              <progress-circle
                v-if="typeof percentePerDay.datasets !== 'undefined'"
                label="% Feminino"
                :value="formatNumber(percentePerDay.datasets[0]?.data[0] ?? 0)"
                :percent="percentePerDay.datasets[0]?.data[0] ?? 0"
                :color="percentePerDay.datasets[0]?.backgroundColor[0] ?? ''" 
              />
            </div>
            <a-skeleton active :paragraph="{ rows: 4 }" v-if="loading" />
            <div class="graph-item">
              <p class="graph-title">{{ translate.percentage_gender }}</p>
              <doughnut-graph
                :chartData="percentePerDay"
                v-if="
                  percentePerDay.datasets &&
                  percentePerDay.datasets.length
                "
              ></doughnut-graph>
              <no-data :chart="percentePerDay" />
            </div>
          </div>
        </div>
        <div class="horizontal-bar-graph-wrapper">
          <div class="graph">
            <div class="period">
              <a-skeleton
                active
                :paragraph="{ rows: 1, width: 1 }"
                v-if="loading"
              />
              <p
                class="graph-title period-title"
                v-if="intervalLabelPeoplePerDayChart && !loading"
              >
                {{ intervalLabelPeoplePerDayChart }}
              </p>
            </div>
            <div class="progress-group">
              <progress-circle
                v-for="(generation, index) in peopleByGeneration.generations"
                :key="index"
                :label="generation.label ?? ''"
                :value="convertToPercentage(generation.total, peopleByGeneration.totalPeople) + '%'"
                :percent="peopleByGeneration.totalPeople ? (generation.total * 100) / peopleByGeneration.totalPeople : 0"
                :color="generation.color" 
              />
            </div>
            <a-skeleton active :paragraph="{ rows: 4 }" v-if="loading" />
            <div class="graph-item">
              <p class="graph-title">{{ translate.age_group }}</p>
              <demographic-bar-graph
                :chartData="infoPeopleByAge"
                v-if="
                  infoPeopleByAge.labels &&
                  infoPeopleByAge.datasets &&
                  infoPeopleByAge.labels.length &&
                  infoPeopleByAge.datasets.length
                "
                id="demographic-bar-chart"
              ></demographic-bar-graph>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressCircle from "@/components/elements/ProgressCircle";
import StatisticCard from "@/components/elements/StatisticCard";
import DemographicLineGraph from "@/components/modules/Graphs/DemographicLineGraph";
import DemographicBarGraph from "@/components/modules/Graphs/DemographicBarGraph";
import StackedBarGraph from "@/components/modules/Graphs/StackedBarGraph";
import DoughnutGraph from "@/components/modules/Graphs/DoughnutGraph";
import PageTitle from "@/components/elements/PageTitle";
import RangeFilter from "@/components/elements/RangeFilter";
import PanelSelectFilter from "@/components/elements/PanelSelectFilter";
import XlsIconButton from "@/components/elements/XlsIconButton";
import NoData from "@/components/elements/NoData";
import PdfIconButton from "@/components/elements/PdfIconButton";

import { mapActions, mapState } from "vuex";

export default {
  name: "Demographics",
  components: {
    StatisticCard,
    ProgressCircle,
    DemographicLineGraph,
    StackedBarGraph,
    DemographicBarGraph,
    DoughnutGraph,
    PageTitle,
    RangeFilter,
    PanelSelectFilter,
    XlsIconButton,
    NoData,
    PdfIconButton
  },
  // {demo}
  computed: {
    ...mapState("Demographics", {
      peoplePerHourCards: (state) => state.peoplePerHourCards,
      peoplePerHourChart: (state) => state.peoplePerHourChart,
      peoplePerDayChart: (state) => state.peoplePerDayChart,
      peoplePerDayProgress: (state) => state.peoplePerDayProgress,
      percentePerDay: (state) => state.percentePerDay,
      peopleByGeneration: (state) => state.peopleByGeneration, 
      infoPeopleByAge: (state) => state.infoPeopleByAge,
      intervalLabelPeoplePerHourChart: (state) => {
        const label = state.periodInfoPeoplePerHourChart;

        return label.inicial ? `${label.inicial} ~ ${label.final}` : "";
      },
      intervalLabelPeoplePerDayChart: (state) => {
        const label = state.periodInfoPeoplePerDayChart;

        return label.inicial ? `${label.inicial} ~ ${label.final}` : "";
      },
    }),
    ...mapState("Graph", {
      cards: (state) => state.cards,
      progress: (state) => state.progress,
    }),
    ...mapState({
      loading: (state) => state.loading,
    }),
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),

    ...mapState("PanelSelectFilter", {
      panel: (state) => {
        const value = state.activePanelId;

        const panel =
          value > 0
            ? state.panelsList.filter(({ id }) => id === value)[0]
            : state.panelsList[0];

        return {
          id: panel?.id,
          address: panel?.address,
          active: panel?.active,
        };
      },
    }),
  },
  methods: {
    ...mapActions("Demographics", [
      "getDemographicsChartXls",
      "getPeoplePerHourChart",
      "getPeoplePerDayChart",
      "getDashboardChartPdf",
    ]),

    ...mapActions("Graph", [
      "getLineChart",
      "getBarChart",
    ]),

    calcPercent(allItems, givenItem) {
      if (!allItems || !givenItem) return;
      const values = Object.values(allItems);
      const percentage = (givenItem / values[0]) * 100;
      return percentage;
    },

    calcPercentTotal(allItems, givenItem) {
      if (!allItems || !givenItem) return;
      const values = Object.values(allItems);
      const total = values.reduce((total, value) => total + value);
      return total;
    },

    formatNumber(num) {
      const n = String(num),
        p = n.indexOf(".");
      return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
        p < 0 || i < p ? `${m}.` : m
      );
    },

    buildCharts() {
      this.getPeoplePerHourChart();
      this.getPeoplePerDayChart();
      this.getLineChart();
      this.getBarChart();
    },

    handleDownloadPdf() {
      const lineChart = document.getElementById("line-chart");
      const barChart = document.querySelector("#demographic-stacked-bar-chart #bar-chart");
      const doughnutChart = document.getElementById("doughnut-chart");
      const horizontalBarChart = document.querySelector("#demographic-bar-chart #bar-chart");
      const urlLogCustomer = document.querySelector(".customer-logo");

      this.getDashboardChartPdf({
        path: "demographics",
        dataURLLineChart: lineChart?.toDataURL() ?? 'semgrafico',
        dataURLBarChart: barChart.toDataURL(),
        dataURLDoughnutChart: doughnutChart.toDataURL(),
        dataURLHorizontalBarChart: horizontalBarChart.toDataURL(),
        urlLogCustomer: urlLogCustomer.getAttribute("src"),
        addressPanelCustomer: this.panel.address,
        cardsLineChart: {
          periodo: `${this.translate.period} ${this.intervalLabelPeoplePerHourChart}`,
          total: this.formatNumber(this.cards.pedestres),
          feminino: this.peoplePerHourCards.feminino ? this.calcPercent(this.peoplePerHourCards, this.peoplePerHourCards.feminino ?? 0)?.toFixed(0) + '%' : 0,
          masculino: this.peoplePerHourCards.masculino ? this.calcPercent(this.peoplePerHourCards, this.peoplePerHourCards.masculino ?? 0)?.toFixed(0) + '%' : 0,
        },
        progressBarChart: {
          periodo: `${this.translate.period} ${this.intervalLabelPeoplePerDayChart}`,
          total: this.formatNumber(this.progress.pedestres),
          feminino: this.peoplePerDayProgress.feminino ? this.calcPercent(this.peoplePerDayProgress, this.peoplePerDayProgress.feminino ?? 0)?.toFixed(0) + '%' : 0,
          masculino: this.peoplePerDayProgress.masculino ? this.calcPercent(this.peoplePerDayProgress, this.peoplePerDayProgress.masculino ?? 0)?.toFixed(0) + '%' : 0,
        },
        progressDoughnutChart: {
          periodo: `${this.translate.period} ${this.intervalLabelPeoplePerDayChart}`,
          feminino: this.percentePerDay.datasets[0]?.data[0],
          masculino: this.percentePerDay.datasets[0]?.data[1],
        },
        progressHorizontalBarChart: {
          periodo: `${this.translate.period} ${this.intervalLabelPeoplePerDayChart}`,
          generations: this.peopleByGeneration.generations,
          totalPeople: this.peopleByGeneration.totalPeople,
        },
      });
    },
    convertToPercentage(value, total) {
      return value ? ((value / total) * 100).toFixed(0) : 0;
    }
  },
  created() {
    this.buildCharts();
  },
};
</script>

<style lang="scss" scoped>
.panel-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 15px 0;
  padding: 10px 10px 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  .panel-disabled {
    background: #fff1f0;
    color: #f5222d;
    border: 1px solid #ffa39e;
    padding: 2px 10px;
    border-radius: 4px;
    max-height: 30px;
    align-self: center;
  }

  .btns-wrapper {
    margin-top: 1.5rem;
    .download-icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .line-bar-container,
  .doughnut-horizontal-container {
    display: flex;
    flex-direction: column;
  }

  .line-graph-wrapper,
  .bar-graph-wrapper,
  .doughnut-graph-wrapper,
  .horizontal-bar-graph-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 15px;
    padding-top: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }
}

.card-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  padding: 0 20px;
}

.progress-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0 20px;
}

.graph {
  max-width: 100%;
  display: grid;
  padding: 20px;
  gap: 20px;

  .graph-item {
    max-width: 100%;
  }

  & .graph-title {
    font-weight: var(--text-bold);
    text-align: center;
    font-size: var(--text-18);
  }
}

.period-title {
  font-weight: bold;
  text-align: center;
  font-size: var(--text-18);
}

@media screen and (min-width: 460px) {
  .panel-disabled {
    margin-top: 20px;
  }
  .card-group {
    grid-template-columns: repeat(1, 1fr);
  }

  .container {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .panel-disabled {
    margin-bottom: 5px;
    margin-top: 0;
  }

  .card-group {
    grid-template-columns: repeat(3, 1fr);
  }

  .progress-group {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 930px) {
  .container {
    .doughnut-horizontal-container {
      flex-direction: row;
    }

    .doughnut-graph-wrapper {
      margin-right: 7.5px;
    }

    .horizontal-bar-graph-wrapper {
      margin-left: 7.5px;
    }
  }
}

@media screen and (min-width: 1340px) {
  .panel-container {
    padding-bottom: 0;
  }

  .container {
    .line-bar-container {
      flex-direction: row;
    }

    .line-graph-wrapper {
      margin-right: 7.5px;
    }

    .bar-graph-wrapper {
      margin-left: 7.5px;
    }

    .period {
      .period-title {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .panel-container {
    justify-content: space-between;

    .download-icons {
      margin-bottom: 8px;
    }
  }
}

@media screen and (min-width: 1640px) {
  .container {
    .period {
      .period-title {
        margin-bottom: 20px;
      }
    }

    .card-group {
      margin-bottom: 0;
    }

    .progress-group {
      padding-left: 12px;
    }
  }
}
</style>
