import { httpClient } from "./index";

export const getUserData = () => httpClient.get("/user/auth-info");

export const changeSessionLocale = (locale) =>
  httpClient.post("/session-locale/change", {
    locale: locale,
  });

export const getCustomersList = () =>
  httpClient.get("/customer/list");

export const changeAuthCustomer = (customerId) =>
  httpClient.post("/user/change-auth-customer", {
    customer_id: customerId
  });

export const getPaypalSettings = () =>
  httpClient.get("/paypal/product-setting");