<template>
  <div class="download-report">
    <a-tooltip class="download-btn">
      <template slot="title">
        <span>{{ title }}</span>
      </template>
      <a-icon @click="handleClick" type="file-pdf" theme="twoTone" two-tone-color="#E01931"/>
      <p class="report" v-if="label">{{ label }}</p>
    </a-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.download-report {
  width: 32px;
  height: 32px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  padding-top: 3.5px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3), 0px 0px 2px rgba(0, 0, 0, 0.3);

  &:hover {
    transform: scale(1.04);
    background: #f9f9f9;
  }
}

.download-btn {
  font-size: 24px;
}

.report {
  color: var(--gray-80);
}
</style>
