<template>
  <div class="filter-item">
    <template>
      <p class="label">{{ title }}</p>
      <a-range-picker format="YYYY-MM-DD" :locale="locale" @change="setFilter">
      </a-range-picker>
    </template>
  </div>
</template>

<script>
import * as pt_BR from "ant-design-vue/es/date-picker/locale/pt_BR";
import * as es_ES from "ant-design-vue/es/date-picker/locale/es_ES";
import * as en_US from "ant-design-vue/es/date-picker/locale/en_US";
import { mapMutations, mapState } from "vuex";

export default {
  name: "RangeFilter",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      locale: {},
    };
  },

  computed: {
    ...mapState("User", {
      localeLanguage: (state) => state.client,
    }),

    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
  },

  methods: {
    ...mapMutations("RangeFilter", {
      setDateInterval: "SET_DATE_INTERVAL",
    }),

    handleLocaleImport() {
      const localeConfig = {
        ES: es_ES.default,
        EN: en_US.default,
        PT: pt_BR.default,
      };

      this.locale = localeConfig[this.localeLanguage.language] || pt_BR.default;
    },

    setFilter(dates, dateStrings) {
      this.setDateInterval({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });

      this.$emit("afterSetFilter");
    },
  },
  beforeMount() {
    this.handleLocaleImport();
  },
};
</script>

<style lang="scss" scoped>
.filter-item {
  max-width: 280px;
  .label {
    font-weight: var(--text-semibold);
    line-height: 21px;
    color: var(--gray-80);
    margin-bottom: 0;
  }

  & .select {
    max-width: 100%;
    width: fit-content;
  }
}

.ant-calendar-picker {
  max-width: 100%;
  width: 100%;
}
</style>
