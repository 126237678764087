export const getToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if(!user) return null;
  return user?.access_token;
};

export const getNewToken = () => {
  const token = localStorage.getItem("access_token");
  if(!token) return null;
  return token;
}
