<template>
  <a-menu
    theme="light"
    mode="inline"
    :default-selected-keys="[$route.fullPath]"
  >
    <!-- <a-menu-item key="/account/my-data">
      <router-link to="/account/my-data">
        <a-icon type="user" />
        <span>{{ translate.my_data }}</span>
      </router-link>
    </a-menu-item> -->
    <a-menu-item key="/account/my-panels">
      <router-link to="/account/my-panels">
        <a-icon type="windows" />
        <span>{{ translate.my_panels }}</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="/account/payment" v-if="settings.exists">
      <router-link to="/account/payment">
        <a-icon type="credit-card" />
        <span>{{ translate.payment }}</span>
      </router-link>
    </a-menu-item>
  </a-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AccountNavLinks",
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
    ...mapState("Paypal", {
      settings: (state) => state.settings,
    }),
  },

  methods: {
    ...mapActions("Paypal", ["getSettings"]),

    appendPaypalSdkScript() {},
  },

  created() {
    this.getSettings();
  },
};
</script>

<style>
</style>