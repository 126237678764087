import { getPanel, panelUpdate } from "../../../api/panel.api";
import { showErrorNotification, showSuccessNotification } from "../../../utils/error-notifications";

export const Panel = {
  state: {
    panel: {},
  },

  mutations: {
    SET_PANEL(state, panel) {
      state.panel = panel;
    },
  },

  actions: {
    async getPanel({ commit }, panelId) {
      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await getPanel({ panelId });

        commit("SET_PANEL", body.panel);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async panelUpdate({ commit }, { panelId, panel }) {
      commit("SET_LOADING", true, { root: true });
      try {
        await panelUpdate({ panelId, ...panel });
        showSuccessNotification("Painel atualizado com sucesso!");
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  namespaced: true,
};
