import { getTranslation } from "../../../api/translate.api";
import { showErrorNotification } from "../../../utils/error-notifications";

export const Translate = {
  state: {
    labels: {}
  },
  mutations: {
    SET_LABELS(state, labels) {
      state.labels = labels;
    },
  },
  actions: {
    async getLabels({ commit }) {

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await getTranslation();

        commit("SET_LABELS", body.labels);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {},
  namespaced: true,
};
