import { getPanelsList } from "../../../api/panel.api";
import { showErrorNotification } from "../../../utils/error-notifications";

export const PanelSelectFilter = {
  state: {
    panelsList: [],
    activePanelId: null,
  },
  mutations: {
    SET_PANELS_LIST(state, panels) {
      state.panelsList = panels;
    },
    SET_ACTIVE_PANEL_ID(state, panelId) {
      state.activePanelId = panelId;
    },
  },
  actions: {
    async getPanels({ state, commit }) {
      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await getPanelsList();

        commit("SET_PANELS_LIST", body.panels);
        commit("SET_ACTIVE_PANEL_ID", state.activePanelId || body.panels[0]?.id);

      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {
    activePanelId: (state) => state.activePanelId,
  },
  namespaced: true,
};
