import { httpClient } from "./index";

export const lineChart = ({
  panel = null,
  startDate = null,
  endDate = null,
  lineFilter = null,
}) =>
  httpClient.get("/dashboard/per-hour", {
    params: {
      id_panel: panel,
      line_filter: lineFilter,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const barChart = ({
  panel = null,
  startDate = null,
  endDate = null,
  classFilter = null,
}) =>
  httpClient.get("/dashboard/per-day", {
    params: {
      id_panel: panel,
      class_filter: classFilter,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const weightedChartPerDay = ({
  panel = null,
  startDate = null,
  endDate = null,
}) =>
  httpClient.get("/dashboard/weighted-per-day", {
    params: {
      id_panel: panel,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const weightedChartPerHour = ({
  panel = null,
  startDate = null,
  endDate = null,
}) =>
  httpClient.get("/dashboard/weighted-per-hour", {
    params: {
      id_panel: panel,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const weightedChartXls = ({
  panel = null,
  startDate = null,
  endDate = null,
}) =>
  httpClient.get("/dashboard/weighted-per-filter-xls", {
    params: {
      id_panel: panel,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const dashboardChartXls = ({
  panel = null,
  startDate = null,
  endDate = null,
}) =>
  httpClient.get("/dashboard/dashboard-per-filter-xls", {
    params: {
      id_panel: panel,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const dashboardChartPdf = ({ panelId = null, payload = {} }) =>
  httpClient.post("/generate-pdf", {
    params: {
      panelId: panelId,
      path: payload.path,
      dataURLLineChart: payload.dataURLLineChart,
      dataURLBarChart: payload.dataURLBarChart,
      urlLogCustomer: payload.urlLogCustomer,
      addressPanelCustomer: payload.addressPanelCustomer,
      cardsLineChart: payload.cardsLineChart,
      progressBarChart: payload.progressBarChart,
    },
  });

export const uploadPdfCustomImage = ({ panelId = null, formData = null }) =>
  httpClient.post(`panels/image-upload/${panelId}`, formData);
