<template>
  <div>
    <a-skeleton active title v-if="loading" />
    <div class="statistic-item" v-if="!loading">
      <a-statistic :title="title" :value="value" style="margin-right: 10px" />
      <a-tooltip class="info-tooltip" v-if="hasTooltip">
        <template slot="title">
          {{ tipMessage }}
        </template>
        <a-icon
          style="color: var(--blue-50)"
          type="info-circle"
          theme="filled"
        />
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    tipMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
};
</script>

<style lang="scss" scoped>
.statistic-item {
  display: flex;
  padding: 20px;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.08), -1px -1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 3px;

  & .info-tooltip {
    margin-top: 3px;
    align-self: flex-end;
  }
}
</style>
