<bar-chart
  :chartdata="chartData"
  :height="height"
  :options="chartOptions"
  :style="style"
></bar-chart>

<script>
import { Bar, mixins } from "vue-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        scales: {
          yAxes: [{
            min: 0,
            stacked: true,
            ticks: {
             stepSize: 50,
             callback: function(value) {
                return value + "%";
              }
            }
          }],
          xAxes: [{
            stacked: true,
          }]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              value = value.toFixed(0);
              return data.datasets[tooltipItem.datasetIndex].label + ': ' + value + '%';
          }
        }
      },
      plugins: {
        datalabels: {
          color: "white",
          font: {
            weight: "bold",
            size: 10
          },
          display: function(context) {
            return context.dataset.data.length <= 30 && context.dataset.data[context.dataIndex] > 0;
          },
          formatter: function(value) {
            return value.toFixed(0) + "%";
          }
        }
      }
    }
  }
},
  mounted() {
    this.addPlugin(ChartDataLabels)
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style></style>
