import store from "../../index";
import { lpr } from "../../../api/lpr.api";
import { showErrorNotification } from "../../../utils/error-notifications";

export const Lpr = {
  state: {
    lprDownloadLinks: [],
    periodInfo: {},
    total: 0,
    error: ""
  },
  mutations: {
    SET_LPR_DOWNLOAD_LINKS(state, downloadLinks) {
      state.lprDownloadLinks = downloadLinks;
    },
    SET_TOTAL(state, data) {
      state.total = data;
    },
    SET_ERROR(state, data) {
      state.error = data;
    }
  },
  actions: {
    async getLprDownloadLinks({ commit, rootGetters }, { panel }) {
      const filters = {
        ...store.getters["RangeFilter/filter"],
      };

      const daysInRange = rootGetters["RangeFilter/daysInRange"]

      const panelName = panel.panel;
      const panelPage = panel.page;

      commit("SET_LOADING", true, { root: true });
      try {
        const {
          data: { body },
        } = await lpr({ ...filters, panelName, panelPage });
        if(body.urls) {
          commit("SET_LPR_DOWNLOAD_LINKS", body.urls);
          commit("SET_TOTAL", daysInRange);
        } else {
          commit("SET_LPR_DOWNLOAD_LINKS", {});
          commit("SET_ERROR", body.error);
        }
      } catch (error) {
        showErrorNotification(error);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  namespaced: true,
};
