  <doughnut-graph :chartdata="chartData" :options="options"/>

<script>
import { Doughnut, mixins } from "vue-chartjs";
export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        cutoutPercentage: 70,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
          },
        },
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style>
</style>