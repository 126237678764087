import { getUserData, changeSessionLocale, getCustomersList, changeAuthCustomer, userUpdate } from "../../../api/user.api";
import { showErrorNotification, showSuccessNotification } from "../../../utils/error-notifications";

export const User = {
  state: {
    user: {},
    client: {},
    activeClassFilter: null,
    activeLineFilter: null,
    customerImg: null,
    canChangeCustomer: false,
    customersList: [],
    dashComplete: false
  },
  mutations: {
    SET_USER_DATA(state, { name, email, variable_impacts }) {
      state.user = { ...state.user, name, email, variable_impacts };
    },

    SET_CUSTOMER(state, customer) {
      state.client = customer;
    },

    SET_ACTIVE_CLASS_FILTER(state, data) {
      state.activeClassFilter = data;
    },

    SET_ACTIVE_LINE_FILTER(state, data) {
      state.activeLineFilter = data;
    },

    SET_CUSTOMER_IMG(state, data) {
      state.customerImg = data;
    },

    RESET_USER_STATE(state) {
      const defaultState = {
        user: {},
        panelsList: [],
        client: {},
        activeClassFilter: null,
        activeLineFilter: null,
        customerImg: null,
        customersList: {},
      };

      Object.assign(state, defaultState);
    },

    SET_CAN_CHANGE_CUSTOMER(state, data) {
      state.canChangeCustomer = data;
    },

    SET_DASH_COMPLETE(state, data) {
      state.dashComplete = data;
    },

    SET_CUSTOMERS_LIST(state, data) {
      state.customersList = data;
    },
  },
  actions: {
    async getUser({ commit }) {
      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await getUserData();

        const customerImg =
          "undefined" !== typeof body.customer.logo
            ? `https://api.datavisiooh.com/storage/logo_customer/${body.customer.logo}`
            : null;

        commit("SET_USER_DATA", body.user);
        commit("SET_CUSTOMER", body.customer);
        commit("SET_CUSTOMER_IMG", customerImg);
        commit("SET_CAN_CHANGE_CUSTOMER", body.can_change_customer);
        commit("SET_DASH_COMPLETE", body.dash_complete);
      } catch (error) {
        if (error.response?.status === 401) return;
        showErrorNotification(
          `Erro ao buscar dados do usuário. ERROR: ${error}`
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async changeSessionLocale({ commit }, locale) {
      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await changeSessionLocale(locale);

        if (data.success) window.location.reload(true);
      } catch (e) {
        showErrorNotification(`Não foi possivel alterar o idioma. ERROR: ${e}`);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    resetUserState({ commit }) {
      commit("RESET_USER_STATE");
    },

    async getCustomersList({ commit }) {
      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await getCustomersList();

        commit("SET_CUSTOMERS_LIST", body.customers_list);
      } catch (error) {
        showErrorNotification(error);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async changeAuthCustomer({ commit }, customerId) {
      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await changeAuthCustomer(customerId);

        if (true == data.success) window.location.reload(true);
      } catch (error) {
        showErrorNotification(error);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async userUpdate({ commit }, { userId, user }) {
      commit("SET_LOADING", true, { root: true });
      try {
        await userUpdate({ userId, ...user });
        showSuccessNotification("Dados alterados com sucesso!");
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

  },
  getters: {
    getActiveLineFilter: (state) => state.activeLineFilter,
    getActiveClassFilter: (state) => state.activeClassFilter,
    clientId: (state) => state.client.id,
  },
  namespaced: true,
};
