<template>
  <a-spin :spinning="loading">
    <router-view :key="$route.fullPath"></router-view>
  </a-spin>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
};
</script>

<style lang="scss"></style>
