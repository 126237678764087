<template>
  <a-layout>
    <a-layout-sider
      class="account-sidebar"
      v-model="collapsed"
      width="250"
      collapse-width="0"
      breakpoint="xxl"
      theme="light"
      :trigger="null"
      collapsible
    >
      <div class="logo" v-if="collapsed">
        <a href="/home">
          <img
            class="collapsed"
            src="../../assets/img/logo_collapsed.png"
            alt=""
          />
        </a>
      </div>
      <div v-if="!collapsed" class="expanded-container">
        <div class="menu-container">
          <div class="logo" v-if="!collapsed">
            <a href="/home">
              <img class="expanded" src="../../assets/img/logo_h.png" alt="" />
            </a>
          </div>
          <div class="logo" v-if="collapsed">
            <a href="/home">
              <img
                class="collapsed"
                src="../../assets/img/logo_collapsed.png"
                alt=""
              />
            </a>
          </div>
        </div>
        <a-divider type="horizontal" style="margin: 0"></a-divider>
      </div>
      <div class="customer-logo-wrapper">
        <img
          v-if="!collapsed"
          class="customer-logo"
          :src="customerImg"
          :alt="infoCustomer.name_client"
        />
      </div>
      <account-nav-links></account-nav-links>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="header" style="background: #fff; padding: 0">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <div class="customer-info">
          <h1 class="customer-name">{{ infoCustomer.name_client }}</h1>
        </div>
        <div class="menu">
          <div class="dropdown" v-if="canChangeCustomer">
            <template v-if="customersList.length">
              <a-select
                class="select customer-dropdown"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                placeholder="Selecione o cliente"
                @change="handleChangeCustomer"
              >
                <a-select-option
                  v-for="customer in customersList"
                  :key="customer.id"
                  :value="customer.id"
                >
                  {{ customer.name_client }}
                </a-select-option>
              </a-select>
            </template>
          </div>
          <div class="language-select">
            <a-tooltip>
              <template slot="title">
                <span>Español</span>
              </template>
              <a
                href="javascript:void(0);"
                @click="handleChangeLocale('es_ES')"
              >
                <img
                  src="../../assets/img/es_ES.png"
                  alt=""
                  class="language-flag"
                />
              </a>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">Português</template>
              <a
                href="javascript:void(0);"
                @click="handleChangeLocale('pt_BR')"
              >
                <img
                  src="../../assets/img/pt_BR.png"
                  alt=""
                  class="language-flag"
                />
              </a>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">English</template>
              <a
                href="javascript:void(0);"
                @click="handleChangeLocale('en_US')"
              >
                <img
                  src="../../assets/img/en_US.png"
                  alt=""
                  class="language-flag"
                />
              </a>
            </a-tooltip>
          </div>

          <user-dropdown></user-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '15px',
          padding: '0',
          background: '#fff',
          minHeight: '280px',
        }"
      >
        <router-view></router-view>
        <h2 class="company-copyright">
          {{ translate.footer_info_2 }}
        </h2>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import AccountNavLinks from "@/components/modules/AccountNavLinks";
import UserDropdown from "@/components/elements/UserDropdown.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { AccountNavLinks, UserDropdown },
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
    ...mapState({
      loading: (state) => state.loading,
    }),
    ...mapState("User", {
      infoCustomer: (state) => state.client,
      customerImg: (state) => state.customerImg,
      canChangeCustomer: (state) => state.canChangeCustomer,
      customersList: (state) => state.customersList,
    }),
  },
  methods: {
    ...mapActions("User", [
      "changeSessionLocale",
      "changeAuthCustomer",
      "getUser",
      "getCustomersList",
    ]),
    ...mapActions("Translate", ["getLabels"]),
    handleChangeLocale(locale) {
      this.changeSessionLocale(locale);
    },
    handleChangeCustomer(customerId) {
      this.changeAuthCustomer(customerId);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getUser();
    this.getCustomersList();
    this.getLabels();
  },
};
</script>

<style lang="scss" scoped>
.ant-layout {
  min-height: 100vh;
}
.ant-layout-content {
  background: #f0f2f5 !important;
}
.account-sidebar {
  z-index: 3;
}
.customer-logo-wrapper {
  padding: 5px 0;
  width: 100%;
  text-align: center;
  background: #3687ab;
  .customer-logo {
    max-width: 100%;
    max-height: 100px;
    background: #3687ab;
    position: relative;
    padding: 0 10px;
  }
}
.header {
  background: #fff;
  padding: 0 1rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .customer-info {
    display: none;
    .customer-name {
      font-size: 1.5rem;
      font-weight: var(--text-semibold);
      color: #5f5f5f;
    }
  }
  .menu {
    display: flex;
    .language-select {
      display: flex;
      gap: 0.3rem;
      .language-flag {
        width: 32px;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .dropdown {
      padding: 0 20px;
      position: absolute;
      top: 68px;
      right: 2px;
      z-index: 1;
    }
  }
}
.expanded-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.menu-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.company-name {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.company-copyright {
  font-size: 14px;
  margin: 0;
  text-align: center;
}
.trigger {
  color: var(--blue-50);
}
.logo {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
  & .expanded {
    max-width: 11.25rem;
  }
  & .collapsed {
    max-width: 2.15rem;
  }
}
.customer-dropdown {
  min-width: 200px;
}
@media screen and (min-width: 640px) {
  .header {
    .customer-info {
      display: initial;
    }
  }
}
@media screen and (min-width: 540px) {
  .header {
    .menu {
      .dropdown {
        position: initial;
      }
    }
  }
}
</style>