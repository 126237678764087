<template>
  <div class="wrapper">
    <div class="container">
      <slot name="header"></slot>
      <slot name="content"></slot>
      <slot name="extra"></slot>
      <slot name="feedback"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrapper {
  background-image: none;

  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: var(--medium-space);

  .container {
    width: 100%;
    padding: 5%;
  }
}

@media screen and (min-width: 1024px) {
  .wrapper {
    padding: 0;
    background-image: url("../../assets/img/bg_img.png");
    background-size: 60% 100%;
    background-position: left;
    background-repeat: no-repeat;

    justify-content: center;

    .container {
      height: 100vh;
      width: 40%;
      background: #fff;
      padding: 0 7rem;
      align-self: flex-end;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-height: 600px) and (orientation: landscape) {
  .wrapper {
    .container {
      padding: 0 5%;
    }
  }
}
</style>
