export const getLanguage = {
  PT: {
    remember: "Lembrar",
    reset: "Esqueceu sua senha? Clique aqui",
    recover: "Recuperar senha",
    backlogin: "Voltar ao login",
    pass: "Senha",
    newpass: "Nova senha",
    confirmpass: "Confirmar senha",
    login: "Login",
  },
  ES: {
    remember: "Recordarme",
    reset: "¿Olvidaste tu contraseña?",
    recover: "Recuperar contraseña",
    backlogin: "Atrás para iniciar sesión",
    pass: "contraseña",
    newpass: "Nueva contraseña",
    confirmpass: "Confirmar seña",
    login: "Iniciar Sesión",
  }
}