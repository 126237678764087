import store from "../../index";
import { comparatives, comparativesXls } from "../../../api/comparatives.api";
import { showErrorNotification } from "../../../utils/error-notifications";

export const Comparative = {
  state: {
    list: {},
    periodInfo: {},
  },
  mutations: {
    SET_LIST(state, list) {
      state.list = list;
    },
    SET_PERIOD_INFO(state, data) {
      state.periodInfo = data
    },
  },
  actions: {
    async getComparatives({ commit }) {
      const filters = {
        ...store.getters["RangeFilter/filter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await comparatives(filters);

        commit("SET_LIST", body.comparatives);
        commit("SET_PERIOD_INFO", body.interval);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getComparativesXls({ commit }) {
      const filters = {
        ...store.getters["RangeFilter/filter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await comparativesXls(filters);

        if (data.success) {
          window.location.assign(data.body.download_url);
        }
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {},
  namespaced: true,
};
