<template>
  <div class="panel">
    <div class="filter-item panel-filter">
      <a-skeleton
        :loading="true"
        active
        :paragraph="{ rows: 1, width: 300 }"
        v-if="loading"
      />
      <template v-if="!loading && panelsList.length">
        <p class="label">{{ title }}</p>
        <a-select
          class="select"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          :placeholder="placeholder"
          :defaultValue="panel.id"
          @change="handleChange"
        >
          <a-select-option
            v-for="panel in panelsList"
            :key="panel.id"
            :value="panel.id"
          >
            <span :style="panel.active === 2 ? 'color: #cdcdcd' : ''">
              {{ panel.description }}
              <span style="padding-left: 20px">
                {{ panel.active === 2 ? "[disabled]" : "" }}
              </span>
            </span>
          </a-select-option>
        </a-select>
      </template>
    </div>
    <div class="filter-item">
      <a-skeleton
        :loading="true"
        active
        :paragraph="{ rows: 1, width: 50 }"
        v-if="loading"
      />
      <template v-if="!loading && panelsList.length">
        <p class="label">ID</p>
        <p class="panel-id">{{ panel.name | panelId }}</p>
      </template>
    </div>
    <div class="filter-item">
      <a-skeleton
        :loading="true"
        active
        :paragraph="{ rows: 1, width: 300 }"
        v-if="loading"
      />
      <template v-if="!loading && panelsList.length">
        <p class="label">{{ label }}</p>
        <p class="panel-address">{{ panel.address }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "PanelSelectFilter",
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      panel: {},
      selectedPanel: null,
    };
  },
  watch: {
    selectedPanel(value) {
      if (!value) {
        return (this.panel = {
          id: this.panelsList[0]?.id || "",
          address: this.panelsList[0]?.address || "",
        });
      }

      const panel = this.panelsList.filter(({ id }) => id === value)[0];

      return (this.panel = {
        id: panel?.id,
        name: panel?.name,
        address: panel?.address,
      });
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
    ...mapState("PanelSelectFilter", {
      panelsList(state) {
        let panel = state.panelsList[0];

        if (null !== state.activePanelId) {
          panel = state.panelsList.filter(
            (item) => item.id == state.activePanelId
          )[0];
        }

        this.panel = {
          id: panel?.id,
          name: panel?.name,
          address: panel?.address,
        };

        return state.panelsList || [];
      },
    }),
  },
  filters: {
    panelId: function (panel) {
      return panel.replace(/\D/g, "");
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(panel) {
      this.selectedPanel = panel;
      this.setActivePanel(panel);

      this.$emit("change");
    },
    ...mapActions("PanelSelectFilter", ["getPanels"]),
    ...mapMutations("PanelSelectFilter", {
      setActivePanel: "SET_ACTIVE_PANEL_ID",
    }),
  },
  created() {
    this.getPanels();
  },
};
</script>

<style lang="scss" scoped>
.panel {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .filter-item {
    .label {
      font-weight: var(--text-semibold);
      line-height: 21px;
      color: var(--gray-80);
      margin-bottom: 0;
    }

    .panel-id,
    .panel-address {
      margin-top: 5px;
    }

    & .select {
      width: 100%;
    }
  }
  .panel-filter {
    max-width: 240px;
  }
}

@media screen and (min-width: 530px) {
  .panel {
    gap: 2rem;

    .panel-filter {
      width: 28rem;
      max-width: 400px;
    }
  }
}

@media screen and (min-width: 780px) {
  .panel {
    flex-wrap: nowrap;
  }
}
</style>
