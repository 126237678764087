<template>
  <a-layout>
    <a-layout>
      <a-layout-header class="header" style="background: #fff; padding: 0">
        <div class="logo" v-if="!collapsed">
          <a href="/home">
            <img class="expanded" src="../../assets/img/logo_h.png" alt="" />
          </a>
        </div>
        <div class="logo collapsed">
          <a href="/home">
            <img
              class="collapsed"
              src="../../assets/img/logo_collapsed.png"
              alt=""
            />
          </a>
        </div>
        <div class="customer-logo-wrapper">
          <img
            class="customer-logo"
            :src="customerImg"
            :alt="infoCustomer.name_client"
          />
        </div>
        <div class="menu">
          <div class="dropdown" v-if="canChangeCustomer">
            <template v-if="customersList.length">
              <a-select
                class="select customer-dropdown"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                placeholder="Selecione o cliente"
                @change="handleChangeCustomer"
              >
                <a-select-option
                  v-for="customer in customersList"
                  :key="customer.id"
                  :value="customer.id"
                >
                  {{ customer.name_client }}
                </a-select-option>
              </a-select>
            </template>
          </div>
          <div class="language-select">
            <a-tooltip>
              <template slot="title">
                <span>Español</span>
              </template>
              <a
                href="javascript:void(0);"
                @click="handleChangeLocale('es_ES')"
              >
                <img
                  src="../../assets/img/es_ES.png"
                  alt=""
                  class="language-flag"
                />
              </a>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">Português</template>
              <a
                href="javascript:void(0);"
                @click="handleChangeLocale('pt_BR')"
              >
                <img
                  src="../../assets/img/pt_BR.png"
                  alt=""
                  class="language-flag"
                />
              </a>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">English</template>
              <a
                href="javascript:void(0);"
                @click="handleChangeLocale('en_US')"
              >
                <img
                  src="../../assets/img/en_US.png"
                  alt=""
                  class="language-flag"
                />
              </a>
            </a-tooltip>
          </div>

          <user-dropdown></user-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '15px',
          padding: '0',
          background: '#fff',
          minHeight: '280px',
        }"
      >
        <div class="nav-links">
          <nav-links></nav-links>
        </div>
        <router-view></router-view>
        <h2 class="company-copyright">
          {{ translate.footer_info_2 }}
        </h2>
      </a-layout-content>
    </a-layout>
    <my-settings></my-settings>
    <my-profile></my-profile>
  </a-layout>
</template>

<script>
import NavLinks from "@/components/modules/NavLinks";
import UserDropdown from "../../components/elements/UserDropdown.vue";
import MySettings from "../../components/modules/MySettings.vue";
import MyProfile from "../../components/modules/MyProfile.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { NavLinks, UserDropdown, MySettings, MyProfile },

  data() {
    return {
      collapsed: false,
    };
  },

  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
    ...mapState({
      loading: (state) => state.loading,
    }),
    ...mapState("User", {
      infoCustomer: (state) => state.client,
      customerImg: (state) => state.customerImg,
      canChangeCustomer: (state) => state.canChangeCustomer,
      customersList: (state) => state.customersList,
    }),
  },

  methods: {
    ...mapActions("User", [
      "changeSessionLocale",
      "changeAuthCustomer",
      "getUser",
      "getCustomersList",
    ]),
    ...mapActions("Translate", ["getLabels"]),
    handleChangeLocale(locale) {
      this.changeSessionLocale(locale);
    },

    handleChangeCustomer(customerId) {
      this.changeAuthCustomer(customerId);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },

  created() {
    this.getUser();
    this.getCustomersList();
    this.getLabels();
  },
};
</script>

<style lang="scss" scoped>
.ant-layout {
  min-height: 100vh;
}

.ant-layout-content {
  background: #f0f2f5 !important;

  .ant-menu {
    border: 0;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 -1px 0 1px 1px rgba(63, 63, 68, 0.05);
  }
}

.customer-logo-wrapper {
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .customer-logo {
    max-width: 140px;
    max-height: 60px;
    background: #3687ab;
    position: relative;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

.header {
  background: #fff;
  padding: 0 1rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .menu {
    display: flex;

    .language-select {
      display: flex;
      gap: 0.3rem;

      .language-flag {
        width: 32px;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .dropdown {
      padding: 0 20px;
      position: absolute;
      top: 121px;
      right: 2px;
      z-index: 1;
    }
  }
}

.logo {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;

  & .expanded {
    max-width: 11.25rem;
    display: none;
  }

  & .collapsed {
    max-width: 2.15rem;
  }
}

.customer-dropdown {
  min-width: 200px;
}

.company-copyright {
  font-size: var(--text-16);
  margin: 0;
  text-align: center;
}

@media screen and (min-width: 500px) {
  .header {
    .menu {
      .dropdown {
        position: initial;
      }
    }
  }
}

@media screen and (min-width: 670px) {
  .header {
    .customer-logo-wrapper {
      display: flex;
    }
  }
}

@media screen and (min-width: 780px) {
  .logo {
    & .expanded {
      display: initial;
    }

    & .collapsed {
      display: none;
    }
  }
}
</style>
