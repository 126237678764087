<template>
  <div class="monitoring">
    <div class="page-header">
      <page-title :title="translate.monitoring"></page-title>
    </div>
    <div class="cameras-section">
      <a-card class="camera-card" v-for="camera in cameras" :key="camera.title">
        <div
          class="status"
          :class="
            camera.status === 1
              ? 'on'
              : camera.status === 2
              ? 'off'
              : 'inactive'
          "
        >
          <p v-if="camera.status === 1">On</p>
          <p v-else-if="camera.status === 3">
            <a-icon type="clock-circle" style="font-size: 20px" />
          </p>
          <p v-else>Off</p>
        </div>
        <div class="camera-address">
          <p>{{ camera.title }}</p>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PageTitle from "../components/elements/PageTitle.vue";

export default {
  components: { PageTitle },

  data() {
    return {
      cameras: [],
    };
  },

  watch: {
    panels(data) {
      let cameras = [];

      for (let i = 0; i < data.length; i++) {
        if(data[i].active === 1){
          cameras.push({
            title: data[i].description,
            status: data[i].camera_status,
          });
        }
      }

      this.cameras = cameras;
    },
  },

  computed: {
    ...mapState("PanelSelectFilter", {
      panels: (state) => state.panelsList,
    }),

    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
  },

  methods: {
    ...mapActions("PanelSelectFilter", ["getPanels"]),
  },

  created() {
    this.getPanels();
  },
};
</script>

<style lang="scss" scoped>
.monitoring {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .cameras-section {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    align-self: center;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem 4rem;
    padding: 2rem;
    margin-top: 1rem;

    .camera-card {
      max-height: 100px;
      display: flex;
      flex-direction: column;
      text-align: center;
      position: relative;
      padding: 1rem;
      border-radius: 5px;
      background: #fafafa;
      transition: transform 0.1s linear;
      box-shadow: 1px 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15);

      &:hover {
        transform: scale(1.02);
      }

      .status {
        position: absolute;
        transform: translate(-50px, -15px);
        padding: 0.2rem 0.5rem;
        padding-right: 1rem;
        border-radius: 3px 50px 50px 5px;
        box-shadow: 1px 0 0 1px rgba(63, 63, 68, 0.05),
          0 1px 3px 0 rgba(63, 63, 68, 0.15);

        p {
          margin: 0;
          font-size: var(--text-18);
          color: var(--gray-10);
          text-transform: uppercase;
          font-weight: var(--text-bold);
          margin-top: 5px;
        }
      }

      .on {
        background: #0da13e;
      }

      .off {
        background: #f5222d;
      }

      .inactive {
        background: #a6a8a8;
      }

      .camera-address {
        bottom: 0.5rem;
        font-size: var(--text-18);
        font-weight: var(--text-bold);
        position: relative;

        p {
          word-break: normal;
          width: 255px;
        }
      }
    }

    @media screen and (min-width: 740px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1240px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 1770px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>
