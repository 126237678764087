var render, staticRenderFns
import script from "./DemographicLineGraph.vue?vue&type=script&lang=js"
export * from "./DemographicLineGraph.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./DemographicLineGraph.vue?vue&type=custom&index=0&blockType=line-chart&%3Achartdata=chartData&%3Aheight=height&%3Aoptions=chartOptions"
if (typeof block0 === 'function') block0(component)

export default component.exports