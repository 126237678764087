import store from "../../index";
import {
  peoplePerHourChart,
  peoplePerDayChart,
  demographicsChartXls,
  dashboardChartPdf,
} from "../../../api/demographics.api";
import {
  showErrorNotification,
} from "../../../utils/error-notifications";
import { convertDataToPercentage } from "../../../utils/convertToPercentage";

function convertToPercentage(data) {
  let totalSum = 0;
    data.forEach(dataset => {
        dataset.data.forEach(value => {
            totalSum += value;
        });
    });

    const percentageData = data.map(dataset => ({
        ...dataset,
        data: dataset.data.map(value => (value / totalSum) * 100)
    }));

    return percentageData;
}

function transformPeopleByGenerationData(data) {
  let totalPeople = 0;

  data.forEach(generation => {
    totalPeople += generation.total;
  });

  return { generations: data, totalPeople };
}

function transformPeopleByAgeData(data) {
  const transformedDatasets = [];

  data.datasets.forEach((dataset, index) => {
    transformedDatasets.push({ 
      ...dataset,
      label: data.labels[index],
    });
  });

  return { datasets: convertToPercentage(transformedDatasets), labels: data.labels };
}

export const Demographics = {
  state: {
    panel: {},
    peoplePerHourCards: {},
    peoplePerHourChart: {},
    periodInfoPeoplePerHourChart: {},
    peoplePerDayProgress: {},
    peoplePerDayChart: {},
    periodInfoPeoplePerDayChart: {},
    percentePerDay: {},
    peopleByGeneration: {},
    infoPeopleByAge: {},
  },
  mutations: {
    SET_PEOPLE_PER_HOUR_CARDS(state, data) {
      state.peoplePerHourCards = data;
    },
    SET_PEOPLE_PER_HOUR_CHART(state, data) {
      state.peoplePerHourChart = convertDataToPercentage(data);
    },
    SET_PERIOD_INFO_PEOPLE_PER_HOUR_CHART(state, data) {
      state.periodInfoPeoplePerHourChart = data;
    },
    SET_PEOPLE_PER_DAY_PROGRESS(state, data) {
      state.peoplePerDayProgress = data;
    },
    SET_PEOPLE_PER_DAY_CHART(state, data) {
      state.peoplePerDayChart = convertDataToPercentage(data);
    },
    SET_PERIOD_INFO_PEOPLE_PER_DAY_CHART(state, data) {
      state.periodInfoPeoplePerDayChart = data;
    },
    SET_PERCENT_GENDER_PER_PERIOD(state, data) {
      state.percentePerDay = data;
    },
    SET_PEOPLE_BY_GENERATION(state, data) {
      state.peopleByGeneration = transformPeopleByGenerationData(data);
    },
    SET_INFO_PEOPLE_BY_AGE(state, data) {
      state.infoPeopleByAge = transformPeopleByAgeData(data);
    }
  },
  actions: {
    async getPeoplePerHourChart({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await peoplePerHourChart(filters);

        commit("SET_PEOPLE_PER_HOUR_CARDS", body.cards);
        commit("SET_PEOPLE_PER_HOUR_CHART", body.chartdata);
        commit("SET_PERIOD_INFO_PEOPLE_PER_HOUR_CHART", body.period_date_labels);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getPeoplePerDayChart({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
        classFilter: store.getters["User/getActiveClassFilter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await peoplePerDayChart(filters);

        commit("SET_PEOPLE_PER_DAY_PROGRESS", body.cards);
        commit("SET_PEOPLE_PER_DAY_CHART", body.chartdata);
        commit("SET_PERIOD_INFO_PEOPLE_PER_DAY_CHART", body.period_date_labels);
        commit("SET_PERCENT_GENDER_PER_PERIOD", body.polarChart);
        commit("SET_INFO_PEOPLE_BY_AGE", body.horizontalBarChart);
        commit("SET_PEOPLE_BY_GENERATION", body.cardsGenerations);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getDemographicsChartXls({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await demographicsChartXls(filters);

        if (data.success) window.location.assign(data.body.download_url);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getDashboardChartPdf({ commit }, payload) {
      const panelId = store.getters["PanelSelectFilter/activePanelId"];

      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await dashboardChartPdf({ panelId, payload });

        if (data.success) window.location.assign(data.body.download_url);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {},
  namespaced: true,
};
