import { httpClient } from "./index";

export const campaigns = () => httpClient.get("/dashboard/campaigns");

export const saveCampaign = ({ campaign = null }) =>
  httpClient.post("/dashboard/save-campaign", campaign);

export const pdfCampaign = (campaign_id = null) =>
  httpClient.get(`/dashboard/pdf-campaign/${campaign_id}`);

export const deleteCampaign = (selected_campaigns = null) =>
  httpClient.get("/dashboard/delete-campaign", {
    params: {
      selected_campaigns,
    },
  });
