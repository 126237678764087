import { httpClient } from "./index";

export const getPanelsList = () => httpClient.get("/panels/list");

export const getPanelSetting = ({ 
  panelId = null 
}) => httpClient.get(`/panels/show-setting/${panelId}`);

export const setPanelSetting = ({
  panelId = null,
  car = null,
  bus = null,
  motorcycle = null,
  truck = null,
}) =>
  httpClient.post(`/panels/save-setting/${panelId}`, {
    setting: { car, bus, motorcycle, truck },
  });

export const getPanel = ({ 
  panelId = null 
}) => httpClient.get(`/panel/show/${panelId}`);

export const panelUpdate = ({
  panelId = null,
  description = null,
  address = null,
  utc = null,
}) =>
  httpClient.post(`/panel/update/${panelId}`, {
    panel: { description, address, utc },
  });
