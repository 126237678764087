var render, staticRenderFns
import script from "./BarGraph.vue?vue&type=script&lang=js"
export * from "./BarGraph.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BarGraph.vue?vue&type=custom&index=0&blockType=bar-chart&%3Achartdata=chartData&%3Aheight=height&%3Aoptions=chartOptions&%3Astyle=style"
if (typeof block0 === 'function') block0(component)

export default component.exports