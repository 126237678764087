<template>
  <div class="cpm">
    <page-title title="CPM"></page-title>
    <div class="container">
      <div class="action-btns">
        <a-button
          type="danger"
          icon="delete"
          size="large"
          v-show="hasSelected"
          @click="deleteCampaign"
          class="delete"
        >
          {{ translate.delete_campaign }}
        </a-button>
        <router-link
          to="/new-report"
          class="new-report"
          v-if="campaigns.length && !loading"
        >
          {{ translate.new_report }}
        </router-link>
      </div>
      <div class="table-wrapper" v-if="campaigns.length && !loading">
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :scroll="{ x: 1300, y: 600 }"
          :pagination="false"
          :columns="translateColumns"
          :data-source="campaigns"
        >
          <template slot="action" slot-scope="text, record">
            <PdfIconButton
              :title="translate.print_report"
              @click="getCampaignPdfUrl(record.key)"
            ></PdfIconButton>
          </template>
        </a-table>
      </div>
      <div class="no-campaign" v-else>
        <a-empty>
          <span slot="description" v-if="!loading">{{
            translate.no_campaign
          }}</span>
          <router-link to="/new-report" class="new-report" v-if="!loading">
            {{ translate.new_report }}
          </router-link>
        </a-empty>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/PageTitle";
import PdfIconButton from "@/components/elements/PdfIconButton";

import { mapState, mapActions, mapMutations } from "vuex";

let campaignColumns = [];

export default {
  name: "Cpm",
  components: {
    PageTitle,
    PdfIconButton,
  },

  data() {
    return {
      campaignColumns,
      campaigns: [],
      selectedRowKeys: [],
    };
  },

  watch: {
    campaignsList(data) {
      let campaigns = [];

      for (let i = 0; i < data.length; i++) {
        campaigns.push({
          key: data[i].id,
          name: data[i].name,
          campaign_period: data[i].period,
          campaign_cost: data[i].value,
          impact: data[i].impacts,
          cpm: data[i].cpm_result,
          total_panel_spots: data[i].total_panel_spots || "-",
          impacts_per_spot: data[i].impacts_per_spot || "-",
          total_customer_impact: data[i].total_client_impact || "-",
          campaign_cpm: data[i].campaign_cpm || "-",
        });
      }

      this.campaigns = campaigns;
    },
  },

  computed: {
    ...mapState("Cpm", {
      campaignsList: (state) => state.campaigns,
    }),

    ...mapState({
      loading: (state) => state.loading,
    }),

    ...mapState("Translate", {
      translate: (state) => state.labels,
      translateColumns: (state) => {
        const labels = state.labels;

        return (campaignColumns = [
          {
            title: labels.campaign,
            dataIndex: "name",
            key: "name",
          },
          {
            title: labels.campaign_period,
            dataIndex: "campaign_period",
            key: "campaign_period",
            width: 250,
          },
          {
            title: labels.campaign_cost,
            dataIndex: "campaign_cost",
            key: "campaign_cost",
          },
          {
            title: labels.impact,
            dataIndex: "impact",
            key: "impact",
          },
          {
            title: labels.cpm,
            dataIndex: "cpm",
            key: "cpm",
          },
          {
            title: labels.total_panel_spots,
            dataIndex: "total_panel_spots",
            key: "total_panel_spots",
          },
          {
            title: labels.impacts_per_spot,
            dataIndex: "impacts_per_spot",
            key: "impacts_per_spot",
          },
          {
            title: labels.total_customer_impact,
            dataIndex: "total_customer_impact",
            key: "total_customer_impact",
          },
          {
            title: labels.campaign_cpm,
            dataIndex: "campaign_cpm",
            key: "campaign_cpm",
          },
          {
            title: labels.print_report,
            dataIndex: "print_report",
            key: "print_report",
            scopedSlots: { customRender: "action" },
          },
        ]);
      },
    }),

    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    ...mapActions("Cpm", [
      "getCampaigns",
      "getCampaignPdfUrl",
      "deleteCampaign",
    ]),

    ...mapMutations("Cpm", {
      setSelectedCampaigns: "SET_SELECTED_CAMPAIGNS",
    }),

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;

      this.setSelectedCampaigns(selectedRowKeys);
    },
  },

  created() {
    this.getCampaigns();
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 15px 0;
  padding: 10px 10px 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: center;

  .action-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .delete {
      width: fit-content;
      padding: 0 10px;
      font-size: 14px;
    }

    .new-report {
      margin-left: auto;
      border: 0;
      padding: 10px;
      margin-top: 5px;
      border-radius: 4px;
      background: #36ab5d;
      color: #fff;
      font-weight: var(--text-semibold);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background: #2ca554;
      }
    }
  }

  .table-wrapper {
    width: 100%;
  }

  .no-campaign {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1580px) {
  .container {
    .table-wrapper {
      overflow: hidden;
    }
  }
}
</style>
