<template>
  <a-dropdown>
    <a class="ant-dropdown-link" @click="(event) => event.preventDefault()">
      {{ userName }} <a-icon type="down" />
    </a>
    <a-menu slot="overlay" @click="handleUserOptions">
      <!-- <a-menu-item key="my-profile">
        <a @click="openMyProfile">Meus dados</a>
      </a-menu-item>
      <a-menu-item key="my-settings">
        <a @click="openMySettings">Configurações</a>
      </a-menu-item> -->
      <a-menu-item key="/account" v-if="isAccount">
        <router-link to="account">
          <span>{{ translate.account }}</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="/home" v-else>
        <router-link to="/">
          <span>Dashboard</span>
        </router-link>
      </a-menu-item>    
      <a-menu-item key="logout">
        <a href="javascript:;">{{ translate.exit }}</a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("User", {
      userName: (state) => state.user.name || "-",
    }),
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
    isAccount() {
      return (
        this.$route.path !== "/account/my-data" &&
        this.$route.path !== "/account/my-panels" &&
        this.$route.path !== "/account/payment"
      );
    },
  },
  methods: {
    ...mapActions("Auth", ["logout"]),
    ...mapActions("User", ["resetUserState"]),
    ...mapMutations("Common", {
      setProfileModal: "SET_PROFILE_MODAL_STATE",
      setSettingsModal: "SET_SETTINGS_MODAL_STATE",
    }),

    openMyProfile() {
      this.setProfileModal(true);
    },

    openMySettings() {
      this.setSettingsModal(true);
    },

    handleUserOptions({ key }) {
      if (key === "logout") {
        this.resetUserState();
        return this.logout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #1890ff;
}
</style>
