import { httpClient } from "./index";

export const comparatives = ({
  startDate = null,
  endDate = null,
}) =>
  httpClient.get("/dashboard/comparatives", {
    params: {
      filters : {
        start_date: startDate,
        end_date: endDate,
      }
    },
  });

export const comparativesXls = ({
  startDate = null,
  endDate = null
}) => 
  httpClient.get("/dashboard/comparatives-xls", {
    params: {
      filters: {
        start_date: startDate,
        end_date: endDate
      }
    }
  });