import { getPaypalSettings } from "../../../api/user.api";
import { showErrorNotification } from "../../../utils/error-notifications";

export const Paypal = {
  state: {
    settings: {
      plan_id: null,
      subscription_id: null,
      exists: false,
    },
  },

  mutations: {
    SET_CONFIG(state, settings) {
      state.settings = { ...settings };
    },
  },

  actions: {
    async getSettings({ commit }) {
      commit("SET_LOADING", true, { root: true });
      try {
        const {
          data: { body },
        } = await getPaypalSettings();

        commit("SET_CONFIG", body.settings);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {},
  namespaced: true,
};
