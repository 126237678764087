import { httpClient } from "./index";

export const lpr = ({
    startDate = null,
    endDate = null,
    panelName,
    panelPage
  }) => 
  httpClient.get("/lpr", {
    params: {
      start_date: startDate,
      end_date: endDate,
      panel: panelName,
      page: panelPage
    }
  });
