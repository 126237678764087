<template>
  <a-modal
    :visible="visible"
    title="Configurações"
    @ok="handleOk"
    @cancel="closeModal"
  >
    <div class="content">
      <a-form-item label="Idioma" :colon="false">
        <a-select placeholder="Selecione o idioma" style="width: 100%">
          <a-select-option value="pt">Português</a-select-option>
          <a-select-option value="es">Espanhol</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :colon="false">
        <span slot="label">
          Localização
          <a-tooltip title="Texto de ajuda">
            <a-icon
              type="info-circle"
              theme="filled"
              style="color: var(--blue-50)"
            />
          </a-tooltip>
        </span>
        <a-select placeholder="Selecione o estado" style="width: 100%">
          <a-select-option value="pr">Paraná</a-select-option>
          <a-select-option value="sp">São Paulo</a-select-option>
        </a-select>
      </a-form-item>
      <div class="variables-group">
        <a-form-item :colon="false">
          <span slot="label">
            Carros
            <a-tooltip title="Texto de ajuda">
              <a-icon
                type="info-circle"
                theme="filled"
                style="color: var(--blue-50)"
              />
            </a-tooltip>
          </span>
          <a-input placeholder="Exemplo: 2"> </a-input>
        </a-form-item>
        <a-form-item :colon="false">
          <span slot="label">
            Ônibus
            <a-tooltip title="Texto de ajuda">
              <a-icon
                type="info-circle"
                theme="filled"
                style="color: var(--blue-50)"
              />
            </a-tooltip>
          </span>
          <a-input placeholder="Exemplo: 20"> </a-input>
        </a-form-item>
        <a-form-item :colon="false">
          <span slot="label">
            Caminhões
            <a-tooltip title="Texto de ajuda">
              <a-icon
                type="info-circle"
                theme="filled"
                style="color: var(--blue-50)"
              />
            </a-tooltip>
          </span>
          <a-input placeholder="Exemplo: 1.5"> </a-input>
        </a-form-item>
        <a-form-item :colon="false">
          <span slot="label">
            Motos
            <a-tooltip title="Texto de ajuda">
              <a-icon
                type="info-circle"
                theme="filled"
                style="color: var(--blue-50)"
              />
            </a-tooltip>
          </span>
          <a-input placeholder="Exemplo: 1"> </a-input>
        </a-form-item>
      </div>
    </div>
    <template slot="footer">
      <div class="footer">
        <a-button key="back" @click="closeModal">
          Fechar
        </a-button>
        <a-button
          key="submit"
          type="primary"
          class="dv-primary-btn"
          @click="handleOk"
        >
          Salvar
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("Common", {
      visible: (state) => state.mySettingsModalState,
    }),
  },
  methods: {
    ...mapMutations("Common", {
      setSettingsModalState: "SET_SETTINGS_MODAL_STATE",
    }),

    handleOk() {},
    closeModal() {
      this.setSettingsModalState(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.variables-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}
.footer {
  display: flex;
  justify-content: space-between;
}
</style>
