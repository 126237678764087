import { notification } from "ant-design-vue";

export const showErrorNotification = (message) => {
  if (message != "Error: Request failed with status code 401") {
    notification.error({
      message: "Erro!",
      description: `Houve um erro. | Detalhes: ${message}`,
    });
  }
};

export const showSuccessNotification = (message) => {
  notification.success({
    message: "Sucesso!",
    description: message,
  });
}