import { httpClient } from "./index";

export const authenticateUser = ({ email, password }) =>
  httpClient.post("/auth/login", { email, password });

export const recoverPassword = ({ email }) =>
  httpClient.post("/auth/recover-password", { email });

export const newPassword = ({ token, password, password_confirmation }) =>
  httpClient.post("/auth/new-password", {
    token,
    password,
    password_confirmation,
  });

export const userEmail = ({ email }) =>
  httpClient.post("/auth/logout", { email });
