<template>
  <div>
    <page-title :title="translate.export" />

    <div class="container">
      <RangeFilter />

      <PdfIconButton :title="translate.pdf_export" @click="getPdfExportUrl" />
    </div>

    <div class="content">
      <a-table
        class="table-item"
        :columns="exportColumnsTranslate"
        :dataSource="panels"
        :scroll="{ x: 450, y: 600 }"
        :pagination="false"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: handleToggleCheck,
          hideDefaultSelections: true,
          selections: false,
        }"
      >
        <span
          slot="active"
          slot-scope="active"
          v-if="active === 'disabled'"
        >
          <a-tag color="red">
            {{ active }}
          </a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import PageTitle from "../components/elements/PageTitle.vue";
import RangeFilter from "@/components/elements/RangeFilter";
import PdfIconButton from "@/components/elements/PdfIconButton";

let exportColumns = [];

export default {
  components: {
    PageTitle,
    RangeFilter,
    PdfIconButton,
  },
  data() {
    return {
      selectedRows: [],
      selectedRowKeys: [],
      exportColumns,
      panels: [],
    };
  },
  watch: {
    panelsList(data) {
      let panels = [];

      for (let i = 0; i < data.length; i++) {
        panels.push({
          key: data[i].id,
          identification: data[i].description,
          active: `${data[i].active === 2 ? "disabled" : ""}`,
          address: data[i].address,
        });
      }

      this.panels = panels;
    },
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
      exportColumnsTranslate: (state) => {
        const labels = state.labels;

        return (exportColumns = [
          {
            title: labels.identification,
            dataIndex: "identification",
            key: "identification",
            width: 650
          },
          {
            title: "",
            dataIndex: "active",
            key: "active",
            scopedSlots: { customRender: "active" },
          },
          {
            title: labels.address,
            dataIndex: "address",
            key: "address",
          },
        ]);
      },
    }),

    ...mapState("PanelSelectFilter", {
      panelsList: (state) => state.panelsList,
    }),
  },
  methods: {
    ...mapMutations("PdfExport", {
      setSelectedPanels: "SET_SELECTED_PANELS",
    }),

    ...mapActions("PanelSelectFilter", ["getPanels"]),

    ...mapActions("PdfExport", ["getPdfExportUrl"]),

    handleToggleCheck(selectedRowKeys, selectedRows) {
      this.setSelectedPanels(selectedRowKeys);

      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
  },
  created() {
    this.getPanels();
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.content {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  .export-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & .wrapper {
      margin: 0;
    }
  }

  .filters {
    display: flex;
    align-items: center;

    & p {
      margin-right: 1rem;
      margin-bottom: 0;
      color: green;
    }

    & .filters-divider {
      height: 100%;
      margin: 0 1rem 0 0;
    }
  }
}
</style>
