<template>
  <a-modal
    :visible="visible"
    title="Meus dados"
    @ok="handleOk"
    @cancel="closeModal"
  >
    <div class="email-area">
      <a-form-item label="Email" :colon="false">
        <a-input placeholder="exemplo@exemplo.com.br"></a-input>
      </a-form-item>
      <a-divider></a-divider>
    </div>
    <div class="password-area">
      <subtitle>Alterar senha</subtitle>
      <a-form-item label="Senha atual" :colon="false">
        <a-input-password placeholder="********"></a-input-password>
      </a-form-item>
      <a-form-item label="Nova senha" :colon="false">
        <a-input-password placeholder="********"></a-input-password>
      </a-form-item>
      <a-form-item label="Confirmar senha" :colon="false">
        <a-input-password placeholder="********"></a-input-password>
      </a-form-item>
      <div class="action-btns">
        <a-button type="primary" class="dv-primary-btn">Alterar senha</a-button>
      </div>
    </div>
    <template slot="footer">
      <div class="footer">
        <a-button key="back" @click="closeModal">
          Fechar
        </a-button>
        <a-button
          key="submit"
          type="primary"
          class="dv-primary-btn"
          @click="handleOk"
        >
          Salvar
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Subtitle from "../elements/Subtitle.vue";

export default {
  components: { Subtitle },
  computed: {
    ...mapState("Common", {
      visible: (state) => state.myProfileModalState,
    }),
  },
  methods: {
    ...mapMutations("Common", {
      setMyProfileModalState: "SET_PROFILE_MODAL_STATE",
    }),

    handleOk() {},
    closeModal() {
      this.setMyProfileModalState(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btns {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.footer {
  display: flex;
  justify-content: space-between;
}
</style>
