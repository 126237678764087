import { httpClient } from "./index";

export const peoplePerHourChart = ({
  panel = null,
  startDate = null,
  endDate = null,
}) =>
  httpClient.get("/dashboard/people-per-hour", {
    params: {
      id_panel: panel,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const peoplePerDayChart = ({
  panel = null,
  startDate = null,
  endDate = null,
  classFilter = null,
}) =>
  httpClient.get("/dashboard/people-per-day", {
    params: {
      id_panel: panel,
      class_filter: classFilter,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const demographicsChartXls = ({
  panel = null,
  startDate = null,
  endDate = null,
}) =>
  httpClient.get("/dashboard/demographics-per-filter-xls", {
    params: {
      id_panel: panel,
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  });

export const dashboardChartPdf = ({ panelId = null, payload = {} }) =>
  httpClient.post("/generate-pdf", {
    params: {
      panelId: panelId,
      path: payload.path,
      dataURLLineChart: payload.dataURLLineChart,
      dataURLBarChart: payload.dataURLBarChart,
      dataURLDoughnutChart: payload.dataURLDoughnutChart,
      dataURLHorizontalBarChart: payload.dataURLHorizontalBarChart,
      urlLogCustomer: payload.urlLogCustomer,
      addressPanelCustomer: payload.addressPanelCustomer,
      cardsLineChart: payload.cardsLineChart,
      progressBarChart: payload.progressBarChart,
      progressDoughnutChart: payload.progressDoughnutChart,
      progressHorizontalBarChart: payload.progressHorizontalBarChart,
    },
  });