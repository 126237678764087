<line-chart :chartdata="chartData" :height="height" :options="chartOptions" />

<script>
import { Line, mixins  } from "vue-chartjs";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        scales: {
          yAxes: [{
            stacked: true,
            min: 0,
            ticks: {
              stepSize: 50,
              callback: function(value) {
                return value + "%";
              }
            }
          }],
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]?.toFixed(0);
              return data.datasets[tooltipItem.datasetIndex].label + ': ' + value + '%';
            }
          }
        },
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style></style>
