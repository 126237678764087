import store from "../../index";
import { 
  statistics
} from "../../../api/statistics.api";
import { showErrorNotification } from "../../../utils/error-notifications";

export const Statistic = {
  state: {
    cards: {},
    filterWithWeekend: false,
    selectedPanel: null
  },
  mutations: {
    SET_CARDS(state, cards) {
      state.cards = cards;
    },
    SET_FILTER_TYPE(state, type) {
      state.filterType = type; 
    },
    SET_FILTER_WITH_WEEKEND(state, withWeekend) {
      state.filterWithWeekend = withWeekend;
    },
  },
  actions: {
    async getStatistics({ commit }) {

      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
        withWeekend: store.getters["Statistic/filterWithWeekend"]
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await statistics(filters);

        commit("SET_CARDS", body.statistics);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  getters: {
    filterWithWeekend: (state) => state.filterWithWeekend,
  },
  namespaced: true,
};
