<template>
  <div class="content">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
}
</style>