<template>
  <div class="panel-form">
    <label for="identification">{{ translate.identification }}</label>
    <input
      type="text"
      required
      id="identification"
      v-model="myPanel.description"
    />
    <label for="address">{{ translate.address }}</label>
    <input 
      type="text" 
      required 
      id="address" 
      v-model="myPanel.address" 
    />

    <label for="timezone">{{translate.timezone}}</label>
    <input
      type="text"
      id="timezone"
      v-model="panel.utc"
      disabled=""
    />

    <a-divider></a-divider>
    <div class="submit-btns">
      <a @click.prevent="handleCancel" class="btn cancel-btn" href="/meus-paineis"
        >Cancelar
      </a>
      <input
        @click="handleSave"
        class="btn save-btn"
        type="submit"
        value="Salvar"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PanelModalForm",
  data() {
    return {
      myPanel: {
        description: null,
        address: null,
        utc: null,
      },
    };
  },
  watch: {
    panel(value) {
      if (!value) return;
      this.myPanel = { ...value };
    },
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),

    ...mapState("Panel", {
      panel: (state) => state.panel,
    }),
  },

  methods: {
    handleSave() {
      this.panelUpdate({ panelId: this.panel.id, panel: this.myPanel });
      this.$emit("handle-save");
    },
    handleCancel() {
      this.$emit("handle-cancel");
    },
    ...mapActions("Panel", ["panelUpdate"]),
  },
  created() {
    this.myPanel = this.panel;
  },
};
</script>

<style lang="scss" scoped>
.panel-form {
  label {
    font-weight: var(--text-bold);
    font-size: var(--text-14);
  }

  input {
    margin-bottom: 20px;
    width: 100%;
    outline: none;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 4px;
    transition: all 0.3s;
    background-color: none;


    &:hover,
    &:focus {
      border-color: #40a9ff;
    }

    &:nth-child(6) {
      margin-bottom: 10px;
    }
  }

  .submit-btns {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .btn {
      border: none;
      padding: 5px 15px;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      width: initial;
      margin-bottom: 0;
    }

    .cancel-btn {
      border: 1px solid #d9d9d9;
      background: none;
      transition: all 0.3s;

      &:hover {
        border-color: #40a9ff;
        color: #40a9ff;
      }
    }

    .save-btn {
      background-color: #1890ff;
      border: 1px solid #1890ff;
      color: #fff;

      &:hover {
        border-color: #40a9ff;
        background-color: #40a9ff;
      }
    }
  }
}
</style>