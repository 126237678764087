<template>
  <div>
    <page-title :title="translate.payment" />
    <div class="payment-methods">
      <div class="instructions">
        <h2 class="title">{{ translate.title_payment }}</h2>
        <p class="description">{{ translate.description_payment_1 }}</p>
        <ul class="list">
          <li class="list-item">
            {{ translate.description_payment_2 }}
          </li>
          <li class="list-item">
            {{ translate.description_payment_3 }}
          </li>
          <li class="list-item">
            {{ translate.description_payment_4 }}
          </li>
          <li class="list-item">
            {{ translate.description_payment_5 }}
          </li>
          <li class="list-item">
            {{ translate.description_payment_6 }}
          </li>
          <li class="list-item">
            {{ translate.description_payment_7 }}
          </li>
        </ul>
      </div>
      <div id="paypal-button-container" v-if="!subscriptionIsActive"></div>
      <div class="has-subscription" v-if="subscriptionIsActive">
        <a-icon type="check-circle" /> {{ translate.active_subscription }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageTitle from "@/components/elements/PageTitle";
import { loadScript } from "@paypal/paypal-js";
let planId = null;

export default {
  name: "Payment",
  components: {
    PageTitle,
  },
  data() {
    return {
      subscriptionIsActive: false,
    };
  },

  watch: {
    settings(setting) {
      if (!setting.exists) return;
      planId = setting.plan_id;
      // this.appendPaypalSdkScript();
      // this.callCreateSubscription();
    },
  },

  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
    ...mapState("Paypal", {
      settings: (state) => state.settings,
    }),
  },

  methods: {
    ...mapActions("Paypal", ["getSettings"]),

    // appendPaypalSdkScript() {},
  },

  created() {
    this.getSettings();
  },
  mounted() {
    loadScript({
      "client-id":
        "AR-ndwEyGMw9eqwzLw73s9BF-lOyy7lfuzBGyn6mA5cKFcU9SGRJWUngOs3sfM3FKOJc6GBAvr-vJsx7&vault=true&intent=subscription",
      "data-sdk-integration-source": "button-factory",
    })
      .then((paypal) => {
        paypal
          .Buttons({
            style: {
              shape: "pill",
              color: "blue",
              height: 55,
              layout: "horizontal",
              label: "subscribe",
              tagline: false,
            },
            createSubscription:  (data, actions) => {
              return actions.subscription.create({
                plan_id: planId,
              });
            },
            onApprove: (data) => {
              //alert(data.subscriptionID);
              this.subscriptionIsActive = data.subscriptionID ? true : false;
            },
          })
          .render("#paypal-button-container")
          .catch((error) => {
            console.error("failed to render the PayPal Buttons", error);
          });
      })
      .catch((error) => {
        console.error("failed to load the PayPal JS SDK script", error);
      });
  },
};
</script>

<style lang="scss" scoped>
.payment-methods {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 20px;
  margin: 15px 0 10px;

  .instructions {
    border-bottom: 1px solid #ddd;
    padding: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-weight: var(--text-semibold);
      margin-right: 0;
    }

    .description {
      max-width: 900px;
      margin-bottom: 2rem;
    }

    .list {
      max-width: 900px;
      .list-item {
        list-style: disc;
        padding: 10px 0;
        color: var(--gray-80);
      }
    }
  }

  #paypal-button-container {
    width: 280px;
  }

  .has-subscription {
    font-size: var(--text-18);
    font-weight: var(--text-semibold);
    color: #17923a;
    background-color: #cdf8d7;
    border-radius: 20px;
    padding: 5px 10px;
  }
}

@media screen and (min-width: 640px) {
  .payment-methods {
    .instructions {
      padding: 0.5rem 2rem 0;

      .title {
        margin-right: 1.5rem;
      }
    }
  }
}
</style>