<template>
  <div class="header">
    <img
        v-if="logoCustomer"
        src="../../assets/img/logo_customer.png"
        alt="TechLatam"
        class="customer-logo"
      />
    <img src="../../assets/img/logo_h2.png" alt="dataVisiooh" class="logo" />
    <h1 class="title">{{title}}</h1>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    if ( new URL(location.href).host == 'adtech.datavisiooh.com' || 
      new URL(location.href).host == 'adtechlatam.datavisiooh.com' ) {
      return {
        logoCustomer: true
      }
    }
    return {
      logoCustomer: false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .customer-logo {
    max-width: 100%;
  }

  .logo {
    width: 100%;
    min-width: 250px;
    max-width: 330px;
  }

  .title {
    font-weight: var(--text-bold);
    font-size: var(--text-18);
    text-align: center;
    margin: var(--medium-space) 0;
  }
}

@media screen and (max-height: 600px) and (orientation: landscape) {
  .header {
    margin-top: 20px;
    .customer-logo, .logo {
      width: 20%;
    }

    .title {
      margin: 5px 0;
    }
  }
}
</style>