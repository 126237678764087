import { httpClient } from "./index";

export const pdfExport = ({
  selected_panels = null,
  startDate = null,
  endDate = null,
}) => {

  return httpClient.get("/dashboard/pdf-export", {
    params: {
      selected_panels: selected_panels,
      filters : {
        start_date: startDate,
        end_date: endDate,
      }
    },
  });
}