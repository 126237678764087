<template>
  <div>
    <page-title :title="translate.statistics"></page-title>
    <div class="statistics">
      <div class="panel-container">
        <PanelSelectFilter
          class="panel-select-filter"
          :title="translate.panel"
          :label="translate.address"
          @change="getStatistics"
        />

        <span class="panel-disabled" v-if="panel.active === 2"
          >disabled</span
        >
        <div class="wrapper">
          <RangeFilter class="range-filter" :title="translate.period" @afterSetFilter="getStatistics" />
        </div>
      </div>

      <div class="content">
        <div class="weekend-filter">
          <a-checkbox @click="setFilterWithWeekend">
            {{ translate.filter_with_weekends }}
          </a-checkbox>
        </div>

        <div class="statistics-cards">
          <!-- predominancias -->
          <statistic-item
            :title="translate.predominance_all"
            :value="predominance.all"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_predominance_all"
          />
          <statistic-item
            :title="translate.predominance_vehicles"
            :value="predominance.vehicles"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_predominance_vehicles"
          />

          <!-- mediana do periodo -->
          <statistic-item
            :title="translate.period_medians_all"
            :value="periodMedians.all"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_period_medians_all"
          />
          <statistic-item
            :title="translate.period_medians_pedestrian"
            :value="periodMedians.pedestrian"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_period_medians_pedestrian"
          />
          <statistic-item
            :title="translate.period_medians_vehicles"
            :value="periodMedians.vehicles"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_period_medians_vehicles"
          />

          <!-- mediana semanal -->
          <statistic-item
            :title="translate.weekly_medians_all"
            :value="weeklyMedians.all"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_weekly_medians_all"
          />
          <statistic-item
            :title="translate.weekly_medians_pedestrian"
            :value="weeklyMedians.pedestrian"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_weekly_medians_pedestrian"
          />
          <statistic-item
            :title="translate.weekly_medians_vehicles"
            :value="weeklyMedians.vehicles"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_weekly_medians_vehicles"
          />

          <!-- mediana mensal -->
          <statistic-item
            :title="translate.monthly_medians_all"
            :value="monthlyMedians.all"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_monthly_medians_all"
          />
          <statistic-item
            :title="translate.monthly_medians_pedestrian"
            :value="monthlyMedians.pedestrian"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_monthly_medians_pedestrian"
          />
          <statistic-item
            :title="translate.monthly_medians_vehicles"
            :value="monthlyMedians.vehicles"
            :hasTooltip="true"
            :tipMessage="translate.tooltip_monthly_medians_vehicles"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import StatisticItem from "../components/elements/StatisticItem.vue";
import RangeFilter from "@/components/elements/RangeFilter";
import PanelSelectFilter from "@/components/elements/PanelSelectFilter";
import PageTitle from "@/components/elements/PageTitle.vue";

export default {
  components: {
    StatisticItem,
    RangeFilter,
    PanelSelectFilter,
    PageTitle,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
    ...mapState("Statistic", {
      cards: (state) => state.cards,
      predominance: (state) => state.cards.predominance || {},
      periodMedians: (state) => state.cards.period_medians || {},
      weeklyMedians: (state) => state.cards.weekly_medians || {},
      monthlyMedians: (state) => state.cards.monthly_medians || {},
    }),
    ...mapState("PanelSelectFilter", {
      panel: (state) => {
        const value = state.activePanelId;

        const panel =
          value > 0
            ? state.panelsList.filter(({ id }) => id === value)[0]
            : state.panelsList[0];

        return {
          id: panel?.id,
          address: panel?.address,
          active: panel?.active,
        };
      },
    }),
  },
  methods: {
    ...mapActions("Statistic", ["getStatistics"]),
    ...mapMutations("Statistic", {
      setType: "SET_FILTER_TYPE",
      setWithWeekend: "SET_FILTER_WITH_WEEKEND",
    }),
    setFilterWithWeekend(event) {
      this.setWithWeekend(event.target.checked);
      this.getStatistics();
    },
  },
  created() {
    this.getStatistics();
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  .panel-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #fff;
    padding: 10px 10px 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin: 15px 0;

    .panel-disabled {
      background: #fff1f0;
      color: #f5222d;
      border: 1px solid #ffa39e;
      padding: 2px 10px;
      border-radius: 4px;
      max-height: 30px;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .weekend-filter {
        display: flex;
        align-items: center;
        border-radius: 3px;

        & p {
          margin-right: 1rem;
          margin-bottom: 0;
          font-weight: bold;
        }

        & .filters-divider {
          height: 70%;
          margin: 0 1rem 0 0;
        }
      }
    }
  }
}

.content {
  background: #fff;
  padding: 20px;
  text-align: end;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .statistics-cards {
    margin: 0 0 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    background: #fff;
    padding-top: 10px;

    div {
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 500px) {
  .content {
    .statistics-cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 840px) {
  .statistics {
    .panel-container {
      grid-template-columns: 1fr;
      gap: 1rem;

      .panel-disabled {
        margin-top: 14px;
      }

      .wrapper {
        flex-direction: row;
        gap: 1rem;
      }
    }
  }

  .content {
    .statistics-cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (min-width: 1260px) {
  .statistics {
    .panel-container {
      grid-template-columns: 1fr;
    }
  }

  .content {
    .statistics-cards {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (min-width: 1590px) {
  .statistics {
    .panel-container {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      .wrapper {
        justify-content: flex-end;
        .weekend-filter {
          align-self: center;
        }
      }
    }

    .content {
      .statistics-cards {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
</style>
