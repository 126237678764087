<template>
  <div class="wrapper">
    <h4 class="subtitle">
      <slot></slot>
    </h4>
    <a-tooltip class="info-tooltip" v-if="hasTooltip">
      <template slot="title">
        {{ tipMessage }}
      </template>
      <a-icon style="color: var(--blue-50)" type="info-circle" theme="filled" />
    </a-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    tipMessage: {
      type: String,
      default: '',
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 300px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .subtitle {
    margin: 0;
    font-size: 1rem;
    font-weight: var(--text-semibold);
    line-height: 1.5rem;
  }

  .info-tooltip {
    margin-left: 1rem;
  }
}
</style>
