
export const RangeFilter = {
  state: {
    start: null,
    end: null,
  },
  mutations: {
    SET_DATE_INTERVAL(state, { startDate, endDate }) {
      state.start = startDate;
      state.end = endDate;
    },
  },
  getters: {
    filter: (state) => {
      return {
        startDate: state.start,
        endDate: state.end,
      };
    },
    daysInRange: (state) => {
      const startDate = new Date(state.start);
      const endDate = new Date(state.end);
      const timeDiff = endDate.getTime() - startDate.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
      return daysDiff;
    }
  },
  namespaced: true,
};
